import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Spinner,
  Table,
  Modal,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import Pagination from "../Common/Pagination";

const BillReports = () => {
  const userState = useSelector((state) => state.loginData.userData);
  const navigate = useNavigate();

  // Variables for Zone-Gat Visitors Data ------------
  const [contractor, setContractor] = useState();
  const [loader, setLoader] = useState(false);

  // Delete Modal and state
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [apiLoader, setApiLoader] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [error, setError] = useState({});
  const [propCodeErr, setPropCodeErr] = useState(false);
  const [propertycode, setPropertyCode] = useState("");
  const [zone, setZone] = useState("");
  const [gat, setGat] = useState("");
  const [extra, setExtra] = useState("");
  const  [searchProperty,setSearchProperty] = useState(false)
  const [zoneList, setZoneList] = useState([]);
  const [zoneB, setZoneB] = useState("");

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState(0);

  // function for pagination

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getZoneList();
  }, []);

  //   useEffect API Calls
  useEffect(() => {
    getVisitReportList();
  }, [docPerPage, pageNo]);

  useEffect( () => {
    if(searchProperty){
      getVisitReportList()
    }
  },[searchProperty] )

  // get List API
  const getVisitReportList = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL +
        "/billDistribution/getAllBillDistributionsPGN",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          propertyCode: !searchProperty ? "" : "1" + zone + gat + propertycode + `.${extra}`,
          zoneno: zoneB !== "" ? zoneB.length > 1 ? zoneB : zoneB.padStart(2,"0") : "",
          visitingPersonContactNo: "",
          documentsPerPage: docPerPage,
          page: pageNo,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setContractor(response);
          setNoOfPages(response.noOfPages);
      setSearchProperty(false)
          setLoader(false);
        } else {
          setContractor();
          setNoOfPages(0);
          setLoader(false);
      setSearchProperty(false)

        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  // Function for Fetching ZONE LIST --------------------
  const getZoneList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/zoneData/getZoneData`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setZoneList(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };

  const openDelete = (item) => {
    setDeleteId(item?.visit_id);
    setShowDelete(true);
  };

  const closeDelete = () => {
    setDeleteReason("");
    setDeleteId();
    setShowDelete(false);
  };

  const validate = () => {
    let errors = {};

    if (!deleteReason) {
      errors.deleteReason = "*Please Enter Delete Reason";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const MavimDeleteBill = async () => {
    if (validate()) {
      await fetch(
        process.env.REACT_APP_BASE_URL + "/billDistribution/mavimDeleteBill",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            visit_id: deleteId,
            mavimDeleteId: userState._id, // login user userId
            mavimDeleteName: userState.name, // login user Name
            mavimDeleteContactNo: userState.contactNo, // login user ContactNo
            mavimDeleteReason: deleteReason,
          }),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            closeDelete();
            getVisitReportList();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handlePropertyCodeSearch = () => {
    if (propertycode.length === 5 && zone.length === 2 && gat.length === 2 && extra.length === 2) {
      setSearchProperty(true)
    } else {
      toast.error("Please Enter Valid Property Code");
      setSearchProperty(false)
    }
  };

  const searchByZone = () => {
    if (zoneB.length > 0) {
      getVisitReportList();
    } else {
      toast.error("Please Select Zone first.");
    }
  };

  return (
    <div className="visitReport">
      <ToastContainer />
      <Container>
        <Row className="justify-content-center">
          <Col
            md={8}
            className="searchCard m-2"
            style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}
          >
            <div className="label mb-2 mx-auto">
              <p>Search by Property Code</p>
            </div>
            <div className="d-flex mt-1 property-div">
              <Form.Control
                className="m-1"
                maxLength={2}
                placeholder="झोन"
                pattern="[0-9]*"
                value={zone}
                onChange={(e) => {
                  if (!/^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                    e.target.setCustomValidity("Please enter only numbers.");
                  } else {
                    e.target.setCustomValidity("");
                    setZone(e.target.value);
                  }
                }}
              />

              <Form.Control
                className="m-1"
                maxLength={2}
                placeholder="गट"
                pattern="[0-9]*"
                value={gat}
                onChange={(e) => {
                  if (!/^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                    e.target.setCustomValidity("Please enter only numbers.");
                  } else {
                    e.target.setCustomValidity("");
                    setGat(e.target.value);
                  }
                }}
              />

              <Form.Control
                className="m-1"
                maxLength={5}
                placeholder="मालमत्ता क्र."
                pattern="[0-9]*"
                value={propertycode}
                onChange={(e) => {
                  if (!/^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                    e.target.setCustomValidity("Please enter only numbers.");
                  } else {
                    e.target.setCustomValidity("");
                    setPropertyCode(e.target.value);
                  }
                }}
              />

              <Form.Control
                className="m-1"
                maxLength={2}
                placeholder="वाढीव"
                pattern="[0-9]*"
                value={extra}
                onChange={(e) => {
                  if (!/^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                    e.target.setCustomValidity("Please enter only numbers.");
                  } else {
                    e.target.setCustomValidity("");
                    setExtra(e.target.value);
                  }
                }}
              />

              <Button
                className="btn-submit ms-3"
                onClick={() => handlePropertyCodeSearch()}
              >
                <Icon.Search className="me-2" size={14} />
                Search
              </Button>
            </div>
          </Col>
          <Col md={4} className="searchCard m-2">
            <div className="label mb-2 mx-auto">
              <p>Search by Zone</p>
            </div>
            <div className="d-flex mt-1">
              <Form.Select
                className="me-2"
                value={zoneB}
                onChange={(e) => {
                  setZoneB(e.target.value)
                }}
              >
                <option value="" selected disabled>
                  {" "}
                  Select Zone
                </option>
                {zoneList !== undefined && zoneList.length > 0 ? (
                  zoneList.map((row) => {
                    return (
                      <option value={row.zoneno}>
                        {row.zonename + "-" + row.zoneno}
                      </option>
                    );
                  })
                ) : (
                  <option value="">No Data Found</option>
                )}
              </Form.Select>
              <Button className="btn-submit" onClick={searchByZone}>
                <Icon.Search className="me-2" size={14} />
                Search
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="visitors mt-2">
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: "65vh" }}>
              <Table responsive="lg" hover>
                <thead>
                  <th>Sr No.</th>
                  <th>Propety Code</th>
                  <th>Propety Owner Name</th>
                  <th>Propety Owner Contact No</th>
                  <th>Propety Address</th>
                  <th>Zone Name</th>
                  <th>Gat Name</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {loader ? (
                    <center>
                      <Spinner />
                    </center>
                  ) : contractor && contractor.data.length > 0 ? (
                    contractor.data.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {pageNo !== 1 ? (
                              <> {index + 1 + docPerPage * (pageNo - 1)}</>
                            ) : (
                              <>{index + 1}</>
                            )}
                          </td>
                          <td>{row?.propertyCode ? row?.propertyCode : "-"}</td>
                          <td>{row?.propertyName ? row?.propertyName : "-"}</td>
                          <td>
                            {row?.propertyContactNo
                              ? row?.propertyContactNo
                              : "-"}
                          </td>
                          <td>
                            {row?.propertyAddress ? row?.propertyAddress : "-"}
                          </td>
                          <td>{row?.zonename ? row?.zonename : "-"}</td>
                          <td>{row?.gatname ? row?.gatname : "-"}</td>
                          <td className="text-center">
                            <Icon.TrashFill
                              className="me-2"
                              size={20}
                              style={{ cursor: "pointer", color: "#dc3545" }}
                              onClick={() => openDelete(row)}
                            />
                            <Icon.Eye
                              className="me-2"
                              style={{ cursor: "pointer" }}
                              size={25}
                              onClick={() =>
                                navigate("/dashboard/view-visit-report", {
                                  state: {
                                    report: row,
                                    comesFrom: "Bill Report",
                                  },
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>No data fouund</tr>
                  )}
                </tbody>
              </Table>
            </div>
            <Row>
              <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                <h6 className="text-start mb-0">
                  <Icon.People className="me-2" />
                  Total - <strong>{contractor?.count}</strong>
                </h6>
              </Col>
              <Col md={8} className="d-flex justify-content-end">
                <Pagination
                  currentPage={pageNo}
                  totalPages={noOfPages}
                  onPageChange={handlePageChange}
                  onDocsPerPage={handleDocsPerPage}
                  docsPerPage={docPerPage}
                />
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
      <Modal
        size="sm"
        show={showDelete}
        onHide={() => closeDelete()}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <h4 className="mb-0">
            <Icon.TrashFill size={20} className="me-2" />
            Delete Bill
          </h4>
        </Modal.Header>
        <Modal.Body>
          {/* <h5>Are sure about deleting the Bill ?</h5> */}
          <Form.Group className="m-2" controlId="formComment">
            <Form.Label>Enter Delete Reason:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter Delete Reason"
              value={deleteReason}
              onChange={(e) => setDeleteReason(e.target.value)}
            />
            {error.deleteReason ? (
              <div style={{ color: "red" }}>{error.deleteReason}</div>
            ) : null}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {apiLoader ? (
            <Spinner variant="primary" />
          ) : (
            <>
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  closeDelete();
                }}
              >
                Cancel
              </Button>

              <Button
                className="btn btn-danger"
                onClick={() => MavimDeleteBill()}
              >
                <Icon.Trash className="me-1" />
                Delete
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BillReports;
