import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import {
  Button,
  Spinner,
  Accordion,
  Nav,
  OverlayTrigger,
  Tooltip,
  Popover,
} from "react-bootstrap";
import PCMC from "../../Assets/PCMC-LOGO.png";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../../Feature/Login/LoginSlice";
import {
  setActiveKey,
  setActiveMenu,
} from "../../Feature/Sidebar/ActiveKeySlice";
import { setLabelDate, setLabelMonth } from "../../Feature/Graph/label";
import Refresh from "../../Assets/Refresh.svg";

const Menubar = () => {
  const userState = useSelector((state) => state.loginData.userData);
  const activeKeyRedux = useSelector(
    (state) => state.activeData.activeNumberKey
  );
  const activeMenu = useSelector((state) => state.activeData?.activeMenu);

  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute("data-visible");
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = "hidden";
    } else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = "auto";
    }
  }

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout(null));
    dispatch(setActiveKey(0));
    dispatch(setLabelDate(""));
    dispatch(setLabelMonth(""));
    navigate("/");
    setLoader(false);
  };

  const entering = (e) => {
    e.children[0].style.borderTopColor = "white";
    e.children[1].style.backgroundColor = "white";
    e.children[0].style.borderRadius = "12px";
    e.children[1].style.borderRadius = "12px";
  };

  const Popup = (
    <Popover>
      <Popover.Body>
        <div className="PopupData">
          <div>
            <Icon.Person className="me-1" size={18} />
            <p>
              <strong>Name - </strong>
              {userState?.name}
            </p>
          </div>
          <div>
            <Icon.ShieldLock className="me-1" size={17} />
            <p>
              <strong>Role - </strong>
              {userState?.usertype_name[0]}
            </p>
          </div>
          <div>
            <Icon.Telephone className="me-2" size={14} />
            <p>
              <strong>Mobile - </strong>
              {userState?.contactNo}
            </p>
          </div>
          <div>
            <Icon.Bullseye className="me-2" size={15} />
            <p>
              <strong>Zone - </strong>
              {userState?.zonename}
            </p>
          </div>
          <div>
            {userState?.isActive ? (
              <Icon.ToggleOn className="me-2" size={15} />
            ) : (
              <Icon.ToggleOff className="me-2" size={15} />
            )}
            <p>
              <strong>Status - </strong>
              {userState?.isActive ? "Active" : "InActive"}
            </p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(
      setActiveKey({
        activeKey: activeKey,
        subActiveKey: subActiveKey,
        activeTab: tabName,
      })
    );
  };

  const handleMenuChange = (activeKey, tabName, event) => {
    dispatch(
      setActiveMenu({
        activeKey: activeKey,
        activeTab: tabName,
      })
    );
    if (activeKey === 0) {
      event.stopPropagation();
    }
  };

  return (
    <nav>
      <Link className="d-flex align-items-center">
        <h6 className="mb-0 ms-2 d-block d-md-none">
          {activeKeyRedux?.activeTab}
        </h6>
        <h5 className="mb-0 ms-2 d-none d-md-block">
          {activeKeyRedux?.activeTab}
        </h5>
        <abbr title="Refresh">
          <img
            src={Refresh}
            alt="refresh"
            className="ms-2 refresh"
            style={{ width: "22px" }}
            onClick={() => {
              window.location.reload(false);
            }}
          />
        </abbr>
      </Link>

      <div
        className="mobile-nav-toggle"
        aria-controls="selectNav"
        aria-expanded="false"
        onClick={setnav}
      >
        <div className="menu-btn__burger"></div>
      </div>

      <ul className="lg-nav">
        <OverlayTrigger
          key="bottom"
          placement="bottom"
          overlay={Popup}
          onEntering={entering}
        >
          <div className="d-flex align-items-center" style={{ border: "none" }}>
            <Icon.PersonFill className="me-2" size={25} />
            <div>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "15px",
                  marginBottom: "-4px",
                }}
              >
                {userState?.name}
              </p>
              <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                {userState?.usertype_name[0]}
              </p>
            </div>{" "}
          </div>
        </OverlayTrigger>

        {/* <Button className='btn btn-logout ms-3' onClick={handleLogout}>{ loader ? <Spinner animation="border" variant="light" className='spinner'/> :<><Icon.BoxArrowLeft className='me-2'/>Logout</>}</Button> */}
      </ul>

      <ul id="selectNav" className="mobile-nav" data-visible="false">
        <div className="d-flex flex-column h-100">
          {userState?.usertype_keyname[0] === "admin" ||
          userState?.usertype_keyname[0] === "internalDev" ? (
            <>
              <Accordion flush defaultActiveKey={activeMenu?.activeKey}>
                <Accordion.Item
                  onClick={(event) => {
                    handleMenuChange(0, "2023-2024", event);
                  }}
                >
                  <Accordion.Header className="newMenu">
                    2023-2024{" "}
                  </Accordion.Header>
                  <Accordion.Body style={{ padding: 0 }}>
                    <Accordion
                      flush
                      defaultActiveKey={activeKeyRedux?.activeKey}
                    >
                      <Accordion.Item
                        eventKey="0"
                        onClick={() => {
                          handleAccordionChange(0, 0, "Home");
                          navigate("/dashboard");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 0 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 0 ? (
                            <Icon.HouseDoorFill className="me-2" />
                          ) : (
                            <Icon.HouseDoor className="me-2 icon" />
                          )}
                          Home
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="1"
                        onClick={() => {
                          handleAccordionChange(1, 1, "Bill Distribution");
                          navigate("/dashboard/bill-distribution");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 1 ? (
                            <Icon.ReceiptCutoff className="me-2" />
                          ) : (
                            <Icon.ReceiptCutoff className="me-2 icon" />
                          )}
                          Bill Distribution
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="2"
                        onClick={() => {
                          handleAccordionChange(2, 2, "Manage Roles");
                          navigate("/dashboard/manage-roles");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 2 ? (
                            <Icon.PersonFillGear className="me-2" />
                          ) : (
                            <Icon.PersonGear className="me-2 icon" />
                          )}
                          Manage Users
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="3"
                        onClick={() => {
                          handleAccordionChange(
                            3,
                            3,
                            "Mahila Wise Bill Watap Report"
                          );
                          navigate("/dashboard/contractors");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 3 ? (
                            <Icon.PeopleFill className="me-2" />
                          ) : (
                            <Icon.People className="me-2 icon" />
                          )}
                          Mahila Wise Bill Watap Report
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="4"
                        onClick={() => {
                          handleAccordionChange(4, 4, "Distribution");
                          navigate("/dashboard/distribution");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 4 ? (
                            <Icon.BarChartFill className="me-2" />
                          ) : (
                            <Icon.BarChart className="me-2 icon" />
                          )}
                          Distribution Report
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="5"
                        onClick={() => {
                          handleAccordionChange(5, 5, "Zonewise Bill Analysis");
                          navigate("/dashboard/zonewise-bill-analysis");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 5 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 5 ? (
                            <Icon.ReceiptCutoff className="me-2" />
                          ) : (
                            <Icon.Receipt className="me-2 icon" />
                          )}
                          Zonewise Bill Analysis
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={6}
                        className={
                          activeKeyRedux.activeKey === 6
                            ? "activeMenu dropDown"
                            : "dropDown"
                        }
                      >
                        <Accordion.Header
                          onClick={() => {
                            handleAccordionChange(
                              6,
                              activeKeyRedux.subActiveKey,
                              activeKeyRedux.activeTab
                            );
                          }}
                        >
                          {activeKeyRedux?.activeKey === 6 ? (
                            <Icon.PeopleFill className="me-2" />
                          ) : (
                            <Icon.People className="me-2 icon" />
                          )}
                          Manage Telecalling
                        </Accordion.Header>

                        <Accordion.Body className="d-flex flex-column">
                          <Button
                            onClick={() => {
                              navigate("/dashboard/call-summary");
                              handleAccordionChange(6, 6.1, "Call Summary");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 6.1
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 6.1 ? (
                              <Icon.ChatTextFill className="me-2" />
                            ) : (
                              <Icon.ChatText className="me-2 icon" />
                            )}{" "}
                            Calling Summary
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/call-logs");
                              handleAccordionChange(6, 6.2, "Call Logs");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 6.2
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 6.2 ? (
                              <Icon.TelephoneForwardFill className="me-2" />
                            ) : (
                              <Icon.TelephoneForward className="me-2 icon" />
                            )}{" "}
                            Call Logs
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/dialed-call-logs");
                              handleAccordionChange(6, 6.3, "Dialed Call Logs");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 6.3
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 6.3 ? (
                              <Icon.TelephoneForwardFill className="me-2" />
                            ) : (
                              <Icon.TelephoneForward className="me-2 icon" />
                            )}
                            Dialed Call Logs
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/update-mobile");
                              handleAccordionChange(6, 6.4, "Update Mobile");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 6.4
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 6.4 ? (
                              <Icon.TelephoneFill className="me-2" />
                            ) : (
                              <Icon.Telephone className="me-2 icon" />
                            )}{" "}
                            Update Mobile
                          </Button>
                          <Button
                            onClick={() => {
                              handleAccordionChange(
                                6,
                                6.5,
                                "Total Call Summary"
                              );
                              navigate("/dashboard/total-calling");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 6.5
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 6.5 ? (
                              <Icon.TelephonePlusFill className="me-2" />
                            ) : (
                              <Icon.TelephonePlus className="me-2 icon" />
                            )}{" "}
                            Total Call Summary
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="7"
                        className={
                          activeKeyRedux.activeKey === 7
                            ? "activeMenu dropDown"
                            : "dropDown"
                        }
                      >
                        <Accordion.Header
                          onClick={() => {
                            handleAccordionChange(
                              7,
                              activeKeyRedux.subActiveKey,
                              activeKeyRedux.activeTab
                            );
                          }}
                        >
                          {activeKeyRedux?.activeKey === 7 ? (
                            <Icon.Coin className="me-2" />
                          ) : (
                            <Icon.Coin className="me-2 icon" />
                          )}
                          Japti Details
                        </Accordion.Header>

                        <Accordion.Body className="d-flex flex-column">
                          <Button
                            onClick={() => {
                              navigate("/dashboard/japti-notice");
                              handleAccordionChange(7, 7.1, "Japti Notice");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 7.1
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 7.1 ? (
                              <Icon.CardHeading className="me-2" />
                            ) : (
                              <Icon.CardHeading className="me-2 icon" />
                            )}{" "}
                            Japti Notice
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/japti-karvai");
                              handleAccordionChange(7, 7.2, "Japti Karvai");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 7.2
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 7.2 ? (
                              <Icon.Coin className="me-2" />
                            ) : (
                              <Icon.Coin className="me-2 icon" />
                            )}{" "}
                            Japti Karvai
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/japti-mukti");
                              handleAccordionChange(7, 7.3, "Japti Mukti");
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 7.3
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 7.3 ? (
                              <Icon.Coin className="me-2" />
                            ) : (
                              <Icon.Coin className="me-2 icon" />
                            )}{" "}
                            Japti Mukti
                          </Button>
                          <Button
                            onClick={() => {
                              navigate("/dashboard/japti-karvai-zone-report");
                              handleAccordionChange(
                                7,
                                7.4,
                                "Japti Karvai Zone Report"
                              );
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 7.4
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 7.4 ? (
                              <Icon.Coin className="me-2" />
                            ) : (
                              <Icon.Coin className="me-2 icon" />
                            )}
                            Karvai Zone Report
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={8}
                        onClick={() => {
                          handleAccordionChange(8, 8, "SMS Sent Report");
                          navigate("/dashboard/sms-sent-report");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 8 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 8 ? (
                            <Icon.EnvelopeAtFill className="me-2" />
                          ) : (
                            <Icon.EnvelopeAt className="me-2 icon" />
                          )}
                          SMS Sent Report
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={9}
                        onClick={() => {
                          handleAccordionChange(9, 9, "SMS Details");
                          navigate("/dashboard/sms-details");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 9 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 9 ? (
                            <Icon.EnvelopeAtFill className="me-2" />
                          ) : (
                            <Icon.EnvelopeAt className="me-2 icon" />
                          )}
                          SMS Details
                        </Accordion.Header>
                      </Accordion.Item>

                      {userState?.usertype_keyname[0] === "internalDev" && (
                        <Accordion.Item
                          eventKey="7"
                          onClick={() => {
                            handleAccordionChange(7, 7.3, "Internal Developer");
                            navigate("/dashboard/internal-dev");
                          }}
                          className={
                            activeKeyRedux.activeKey === 7 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux.activeKey === 7 ? (
                              <Icon.DatabaseFillLock className="me-2" />
                            ) : (
                              <Icon.DatabaseLock className="me-2 icon" />
                            )}
                            Internal Dev
                          </Accordion.Header>
                        </Accordion.Item>
                      )}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion flush>
                <Accordion.Item
                  onClick={(event) => {
                    handleMenuChange(1, "2024-2025", event);
                  }}
                >
                  <Accordion.Header className="newMenu">
                    2024-2025
                  </Accordion.Header>
                  <Accordion.Body style={{ padding: 0 }}>
                    <Accordion
                      flush
                      defaultActiveKey={activeKeyRedux?.activeKey}
                    >
                      {/* New Dashboard Sidebar from here */}
                      <Accordion.Item
                        eventKey={10}
                        onClick={() => {
                          handleAccordionChange(10, 10, "Bill Distribution");
                          navigate("/dashboard/new-home");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 10 ? (
                            <Icon.HouseDoorFill className="me-2" />
                          ) : (
                            <Icon.HouseDoor className="me-2 icon" />
                          )}
                          Bill Distribution
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="2"
                        onClick={() => {
                          handleAccordionChange(14, 14, "Manage Users");
                          navigate("/dashboard/manage-roles");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 14 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 14 ? (
                            <Icon.PersonFillGear className="me-2" />
                          ) : (
                            <Icon.PersonGear className="me-2 icon" />
                          )}
                          Manage Users
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="11"
                        onClick={() => {
                          handleAccordionChange(
                            11,
                            11,
                            "Property Visit Report"
                          );
                          navigate("/dashboard/new-visit-reports");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 11 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 11 ? (
                            <Icon.PeopleFill className="me-2" />
                          ) : (
                            <Icon.People className="me-2 icon" />
                          )}
                          Property Visit Report
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="12"
                        onClick={() => {
                          handleAccordionChange(
                            12,
                            12,
                            "Mahila Wise Bill Watap Report"
                          );
                          navigate("/dashboard/new-contractors");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 12 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 12 ? (
                            <Icon.PeopleFill className="me-2" />
                          ) : (
                            <Icon.People className="me-2 icon" />
                          )}
                          Mahila Wise Bill Watap Report
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="13"
                        onClick={() => {
                          handleAccordionChange(
                            13,
                            13,
                            "Zonewise Bill Analysys"
                          );
                          navigate("/dashboard/new-bill-analysis");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 13 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 12 ? (
                            <Icon.Receipt className="me-2" />
                          ) : (
                            <Icon.Receipt className="me-2 icon" />
                          )}
                          Zonewise bill Analysis
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="18"
                        onClick={() => {
                          handleAccordionChange(
                            18,
                            18,
                            "Gatwise Bill Analysys"
                          );
                          navigate("/dashboard/gatwise-bill-analysis");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 18 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 18 ? (
                            <Icon.Receipt className="me-2" />
                          ) : (
                            <Icon.Receipt className="me-2 icon" />
                          )}
                          Gatwise Bill Analysis
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="16"
                        onClick={() => {
                          handleAccordionChange(16, 16, "Japti Notice");
                          navigate("/dashboard/new-japti-notice");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 16 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 16 ? (
                            <Icon.CardHeading className="me-2" />
                          ) : (
                            <Icon.CardHeading className="me-2 icon" />
                          )}
                          Japti Notice
                        </Accordion.Header>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey={15}
                        className={
                          activeKeyRedux.activeKey === 15
                            ? "activeMenu dropDown"
                            : "dropDown"
                        }
                      >
                        <Accordion.Header
                          onClick={() => {
                            handleAccordionChange(
                              15,
                              activeKeyRedux.subActiveKey,
                              activeKeyRedux.activeTab
                            );
                          }}
                        >
                          {activeKeyRedux?.activeKey === 15 ? (
                            <Icon.Receipt className="me-2" />
                          ) : (
                            <Icon.Receipt className="me-2 icon" />
                          )}
                          Manage Bills
                        </Accordion.Header>

                        <Accordion.Body className="d-flex flex-column">
                          <Button
                            onClick={() => {
                              navigate("/dashboard/deleted-bill-history");
                              handleAccordionChange(
                                15,
                                15.2,
                                "Deleted Bill History"
                              );
                            }}
                            className={
                              activeKeyRedux?.subActiveKey === 15.2
                                ? "accordionNavActive mb-3"
                                : "accordionNav mb-3"
                            }
                          >
                            {activeKeyRedux?.subActiveKey === 15.2 ? (
                              <Icon.ReceiptCutoff className="me-2" />
                            ) : (
                              <Icon.ReceiptCutoff className="me-2 icon" />
                            )}{" "}
                            Deleted Bill History
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item
                        eventKey="17"
                        onClick={() => {
                          handleAccordionChange(17, 17, "Rejected Bills");
                          navigate("/dashboard/rejected-list");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 17 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 17 ? (
                            <Icon.FileEarmarkExcelFill className="me-2" />
                          ) : (
                            <Icon.FileEarmarkExcel className="me-2 icon" />
                          )}
                          Rejected Bills
                        </Accordion.Header>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          ) : (
            ""
          )}

          {userState?.usertype_keyname?.[0] === "supervisor" ? (
            <Accordion flush>
              <Accordion.Item
                eventKey={0}
                onClick={() => {
                  handleAccordionChange(0, 0, "Home");
                  navigate("/dashboard");
                  setnav();
                }}
                className={activeKeyRedux.activeKey === 0 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux.activeKey === 0 ? (
                    <Icon.HouseDoorFill className="me-2" />
                  ) : (
                    <Icon.HouseDoor className="me-2 icon" />
                  )}
                  Home
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={1}
                onClick={() => {
                  handleAccordionChange(1, 1, "Manage Roles");
                  navigate("/dashboard/manage-roles");
                  setnav();
                }}
                className={activeKeyRedux.activeKey === 1 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux.activeKey === 1 ? (
                    <Icon.PersonFillGear className="me-2" />
                  ) : (
                    <Icon.PersonGear className="me-2 icon" />
                  )}
                  Manage Users
                </Accordion.Header>
              </Accordion.Item>
            </Accordion>
          ) : (
            ""
          )}

          {userState?.usertype_keyname?.[0] === "zonalofficer" ? (
            // <Accordion flush>
            //   <Accordion.Item eventKey={0} onClick={() => { handleAccordionChange(0, 0, "Home"); navigate("/dashboard"); setnav(); }} className={activeKeyRedux.activeKey === 0 ? "activeMenu" : ""}>
            //     <Accordion.Header >{activeKeyRedux.activeKey === 0 ? <Icon.HouseDoorFill className='me-2' /> : <Icon.HouseDoor className='me-2 icon' />}Home</Accordion.Header>
            //   </Accordion.Item>

            //   <Accordion.Item eventKey={1} className={activeKeyRedux.activeKey === 1 ? "activeMenu dropDown" : "dropDown"}>
            //     <Accordion.Header onClick={() => { handleAccordionChange(1, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
            //       {activeKeyRedux?.activeKey === 1 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Telecalling
            //     </Accordion.Header>

            //     <Accordion.Body className="d-flex flex-column">
            //       {/* <Button onClick={() => { navigate("/dashboard/zonal-call-summary"); handleAccordionChange(1, 1.1, "Call Summary"); setnav(); }} className={activeKeyRedux?.subActiveKey === 1.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
            //         {activeKeyRedux?.subActiveKey === 1.1 ? <Icon.ChatTextFill className='me-2' /> : <Icon.ChatText className='me-2 icon' />} Calling Summary
            //       </Button> */}
            //       <Button onClick={() => { navigate("/dashboard/zonal-call-logs"); handleAccordionChange(1, 1.2, "Call Logs"); setnav(); }} className={activeKeyRedux?.subActiveKey === 1.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
            //         {activeKeyRedux?.subActiveKey === 1.2 ? <Icon.TelephoneForwardFill className='me-2' /> : <Icon.TelephoneForward className='me-2 icon' />} Call Logs
            //       </Button>
            //       <Button onClick={() => { navigate("/dashboard/zonal-dialed-call-logs"); handleAccordionChange(1, 1.3, "Dialed Call Logs"); setnav(); }} className={activeKeyRedux?.subActiveKey === 1.3 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
            //         {activeKeyRedux?.subActiveKey === 1.3 ? <Icon.TelephoneForwardFill className='me-2' /> : <Icon.TelephoneForward className='me-2 icon' />}Dialed Call Logs
            //       </Button>
            //       {/* <Button onClick={() => { navigate("/dashboard/update-mobile"); handleAccordionChange(1, 1.4, "Update Mobile"); setnav(); }} className={activeKeyRedux?.subActiveKey === 1.4 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
            //         {activeKeyRedux?.subActiveKey === 1.4 ? <Icon.TelephoneFill className='me-2' /> : <Icon.Telephone className='me-2 icon' />} Update Mobile
            //       </Button> */}
            //       <Button onClick={() => { handleAccordionChange(1, 1.5, "Total Call Summary"); navigate("/dashboard/zonal-total-calling"); setnav(); }} className={activeKeyRedux?.subActiveKey === 1.5 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
            //         {activeKeyRedux?.subActiveKey === 1.5 ? <Icon.TelephonePlusFill className='me-2' /> : <Icon.TelephonePlus className='me-2 icon' />} Total Call Summary
            //       </Button>
            //     </Accordion.Body>
            //   </Accordion.Item>

            //   <Accordion.Item eventKey="2" className={activeKeyRedux.activeKey === 2 ? "activeMenu dropDown" : "dropDown"}>
            //     <Accordion.Header onClick={() => { handleAccordionChange(2, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
            //       {activeKeyRedux?.activeKey === 2 ? <Icon.Coin className='me-2' /> : <Icon.Coin className='me-2 icon' />}Japti Details
            //     </Accordion.Header>

            //     <Accordion.Body className="d-flex flex-column">
            //       <Button onClick={() => { handleAccordionChange(2, 2.1, "Japti Notice"); navigate("/dashboard/zonal-japti-karvai"); setnav(); }} className={activeKeyRedux?.subActiveKey === 2.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
            //         {activeKeyRedux?.subActiveKey === 2.1 ? <Icon.CardHeading className='me-2' /> : <Icon.CardHeading className='me-2 icon' />} Japti Notice
            //       </Button>
            //       <Button onClick={() => { handleAccordionChange(2, 2.2, "Japti Karvai"); navigate("/dashboard/zonal-japti-notice"); setnav(); }} className={activeKeyRedux?.subActiveKey === 2.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
            //         {activeKeyRedux?.subActiveKey === 2.2 ? <Icon.Coin className='me-2' /> : <Icon.Coin className='me-2 icon' />} Japti Karvai
            //       </Button>
            //       <Button onClick={() => { handleAccordionChange(2, 2.3, "Japti Mukti"); navigate("/dashboard/zonal-japti-mukti");; setnav(); }} className={activeKeyRedux?.subActiveKey === 2.3 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
            //         {activeKeyRedux?.subActiveKey === 2.3 ? <Icon.Coin className='me-2' /> : <Icon.Coin className='me-2 icon' />} Japti Mukti
            //       </Button>
            //     </Accordion.Body>
            //   </Accordion.Item>

            //   {/* <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Zonal Target"); navigate("/dashboard/zonal-target"); setnav(); }} className={activeKeyRedux.activeKey === 1 ? "activeMenu" : ""}>
            //     <Accordion.Header >{activeKeyRedux.activeKey === 1 ? <Icon.Calendar2CheckFill className='me-2' /> : <Icon.Calendar2Check className='me-2 icon' />}Target Report</Accordion.Header>
            //   </Accordion.Item>

            //   <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Zonal Contractors"); navigate("/dashboard/zonal-contractors"); setnav(); }} className={activeKeyRedux.activeKey === 2 ? "activeMenu" : ""}>
            //     <Accordion.Header >{activeKeyRedux.activeKey === 2 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Mahila Wise Bill Watap Report</Accordion.Header>
            //   </Accordion.Item>

            //   <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Zonal Distributions"); navigate("/dashboard/zonal-distribution"); setnav(); }} className={activeKeyRedux.activeKey === 3 ? "activeMenu" : ""}>
            //     <Accordion.Header >{activeKeyRedux.activeKey === 3 ? <Icon.CashStack className='me-2' /> : <Icon.Cash className='me-2 icon' />}Distribution Report</Accordion.Header>
            //   </Accordion.Item>

            //   <Accordion.Item eventKey={4} onClick={() => { handleAccordionChange(4, 4, "Zonal Distribution History"); navigate("/dashboard/zonal-distribution-history"); setnav(); }} className={activeKeyRedux.activeKey === 4 ? "activeMenu" : ""}>
            //     <Accordion.Header >{activeKeyRedux.activeKey === 4 ? <Icon.ReceiptCutoff className='me-2' /> : <Icon.Receipt className='me-2 icon' />}Distribution History</Accordion.Header>
            //   </Accordion.Item> */}

            // </Accordion>
            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>
              {/* New Dashboard Sidebar from here */}
              <Accordion.Item
                eventKey={10}
                onClick={() => {
                  handleAccordionChange(10, 10, "Bill Distribution");
                  navigate("/dashboard/new-home");
                }}
                className={activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 10 ? (
                    <Icon.HouseDoorFill className="me-2" />
                  ) : (
                    <Icon.HouseDoor className="me-2 icon" />
                  )}
                  Bill Distribution
                </Accordion.Header>
              </Accordion.Item>

              {/* <Accordion.Item
                eventKey="2"
                onClick={() => {
                  handleAccordionChange(14, 14, "Manage Users");
                  navigate("/dashboard/manage-roles");
                }}
                className={
                  activeKeyRedux?.activeKey === 14 ? "activeMenu" : ""
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 14 ? (
                    <Icon.PersonFillGear className="me-2" />
                  ) : (
                    <Icon.PersonGear className="me-2 icon" />
                  )}
                  Manage Users
                </Accordion.Header>
              </Accordion.Item> */}

              <Accordion.Item
                eventKey="11"
                onClick={() => {
                  handleAccordionChange(11, 11, "Property Visit Report");
                  navigate("/dashboard/new-visit-reports");
                }}
                className={activeKeyRedux?.activeKey === 11 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 11 ? (
                    <Icon.PeopleFill className="me-2" />
                  ) : (
                    <Icon.People className="me-2 icon" />
                  )}
                  Property Visit Report
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey="12"
                onClick={() => {
                  handleAccordionChange(
                    12,
                    12,
                    "Mahila Wise Bill Watap Report"
                  );
                  navigate("/dashboard/new-contractors");
                }}
                className={activeKeyRedux?.activeKey === 12 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 12 ? (
                    <Icon.PeopleFill className="me-2" />
                  ) : (
                    <Icon.People className="me-2 icon" />
                  )}
                  Mahila Wise Bill Watap Report
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey="13"
                onClick={() => {
                  handleAccordionChange(13, 13, "Zonewise Bill Analysys");
                  navigate("/dashboard/new-bill-analysis");
                }}
                className={activeKeyRedux?.activeKey === 13 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 13 ? (
                    <Icon.Receipt className="me-2" />
                  ) : (
                    <Icon.Receipt className="me-2 icon" />
                  )}
                  Zonewise Bill Analysis
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey="18"
                onClick={() => {
                  handleAccordionChange(18, 18, "Gatwise Bill Analysys");
                  navigate("/dashboard/gatwise-bill-analysis");
                }}
                className={activeKeyRedux?.activeKey === 18 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 18 ? (
                    <Icon.Receipt className="me-2" />
                  ) : (
                    <Icon.Receipt className="me-2 icon" />
                  )}
                  Gatwise Bill Analysis
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey="16"
                onClick={() => {
                  handleAccordionChange(16, 16, "Japti Notice");
                  navigate("/dashboard/new-japti-notice");
                }}
                className={activeKeyRedux?.activeKey === 16 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 16 ? (
                    <Icon.CardHeading className="me-2" />
                  ) : (
                    <Icon.CardHeading className="me-2 icon" />
                  )}
                  Japti Notice
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={15}
                className={
                  activeKeyRedux.activeKey === 15
                    ? "activeMenu dropDown"
                    : "dropDown"
                }
              >
                <Accordion.Header
                  onClick={() => {
                    handleAccordionChange(
                      15,
                      activeKeyRedux.subActiveKey,
                      activeKeyRedux.activeTab
                    );
                  }}
                >
                  {activeKeyRedux?.activeKey === 15 ? (
                    <Icon.Receipt className="me-2" />
                  ) : (
                    <Icon.Receipt className="me-2 icon" />
                  )}
                  Manage Bills
                </Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  {/* <Button
                      onClick={() => {
                        navigate("/dashboard/bill-report");
                        handleAccordionChange(15, 15.1, "Bill Report");
                      }}
                      className={
                        activeKeyRedux?.subActiveKey === 15.1
                          ? "accordionNavActive mb-3"
                          : "accordionNav mb-3"
                      }
                    >
                      {activeKeyRedux?.subActiveKey === 15.1 ? (
                        <Icon.ReceiptCutoff className="me-2" />
                      ) : (
                        <Icon.ReceiptCutoff className="me-2 icon" />
                      )}{" "}
                      Bill Report
                    </Button> */}

                  <Button
                    onClick={() => {
                      navigate("/dashboard/deleted-bill-history");
                      handleAccordionChange(15, 15.2, "Deleted Bill History");
                    }}
                    className={
                      activeKeyRedux?.subActiveKey === 15.2
                        ? "accordionNavActive mb-3"
                        : "accordionNav mb-3"
                    }
                  >
                    {activeKeyRedux?.subActiveKey === 15.2 ? (
                      <Icon.ReceiptCutoff className="me-2" />
                    ) : (
                      <Icon.ReceiptCutoff className="me-2 icon" />
                    )}{" "}
                    Deleted Bill History
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ) : (
            ""
          )}

          {userState.usertype_keyname[0] === "mavim" ? (
            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>
              {/* New Dashboard Sidebar from here */}
              <Accordion.Item
                eventKey={10}
                onClick={() => {
                  handleAccordionChange(10, 10, "Bill Distribution");
                  navigate("/dashboard/new-home");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 10 ? (
                    <Icon.HouseDoorFill className="me-2" />
                  ) : (
                    <Icon.HouseDoor className="me-2 icon" />
                  )}
                  Bill Distribution
                </Accordion.Header>
              </Accordion.Item>
              {/* 
                      <Accordion.Item
                        eventKey="2"
                        onClick={() => {
                          handleAccordionChange(14, 14, "Manage Users");
                          navigate("/dashboard/manage-roles");
                          setnav();
                        }}
                        className={
                          activeKeyRedux?.activeKey === 14 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 14 ? (
                            <Icon.PersonFillGear className="me-2" />
                          ) : (
                            <Icon.PersonGear className="me-2 icon" />
                          )}
                          Manage Users
                        </Accordion.Header>
                      </Accordion.Item> */}

              <Accordion.Item
                eventKey="11"
                onClick={() => {
                  handleAccordionChange(11, 11, "Property Visit Report");
                  navigate("/dashboard/new-visit-reports");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 11 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 11 ? (
                    <Icon.PeopleFill className="me-2" />
                  ) : (
                    <Icon.People className="me-2 icon" />
                  )}
                  Property Visit Report
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey="12"
                onClick={() => {
                  handleAccordionChange(
                    12,
                    12,
                    "Mahila Wise Bill Watap Report"
                  );
                  navigate("/dashboard/new-contractors");
                  setnav();
                }}
                className={activeKeyRedux?.activeKey === 12 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 12 ? (
                    <Icon.PeopleFill className="me-2" />
                  ) : (
                    <Icon.People className="me-2 icon" />
                  )}
                  Mahila Wise Bill Watap Report
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={15}
                className={
                  activeKeyRedux.activeKey === 15
                    ? "activeMenu dropDown"
                    : "dropDown"
                }
              >
                <Accordion.Header
                  onClick={() => {
                    handleAccordionChange(
                      15,
                      activeKeyRedux.subActiveKey,
                      activeKeyRedux.activeTab
                    );
                  }}
                >
                  {activeKeyRedux?.activeKey === 15 ? (
                    <Icon.Receipt className="me-2" />
                  ) : (
                    <Icon.Receipt className="me-2 icon" />
                  )}
                  Manage Bills
                </Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button
                    onClick={() => {
                      navigate("/dashboard/bill-report");
                      handleAccordionChange(15, 15.1, "Bill Report");
                      setnav();
                    }}
                    className={
                      activeKeyRedux?.subActiveKey === 15.1
                        ? "accordionNavActive mb-3"
                        : "accordionNav mb-3"
                    }
                  >
                    {activeKeyRedux?.subActiveKey === 15.1 ? (
                      <Icon.ReceiptCutoff className="me-2" />
                    ) : (
                      <Icon.ReceiptCutoff className="me-2 icon" />
                    )}{" "}
                    Bill Report
                  </Button>

                  <Button
                    onClick={() => {
                      navigate("/dashboard/deleted-bill-history");
                      handleAccordionChange(15, 15.2, "Deleted Bill History");
                      setnav();
                    }}
                    className={
                      activeKeyRedux?.subActiveKey === 15.2
                        ? "accordionNavActive mb-3"
                        : "accordionNav mb-3"
                    }
                  >
                    {activeKeyRedux?.subActiveKey === 15.2 ? (
                      <Icon.ReceiptCutoff className="me-2" />
                    ) : (
                      <Icon.ReceiptCutoff className="me-2 icon" />
                    )}{" "}
                    Deleted Bill History
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item
                eventKey="17"
                onClick={() => {
                  handleAccordionChange(17, 17, "Rejected Bills");
                  navigate("/dashboard/rejected-list");
                }}
                className={activeKeyRedux?.activeKey === 17 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 17 ? (
                    <Icon.FileEarmarkExcelFill className="me-2" />
                  ) : (
                    <Icon.FileEarmarkExcel className="me-2 icon" />
                  )}
                  Rejected Bills
                </Accordion.Header>
              </Accordion.Item>
            </Accordion>
          ) : (
            ""
          )}

          <div className="px-2 logout-div">
            <Button className="btn btn-logout w-100" onClick={handleLogout}>
              {loader ? (
                <Spinner
                  animation="border"
                  variant="light"
                  className="spinner"
                />
              ) : (
                <>
                  <Icon.BoxArrowLeft className="me-2" />
                  Logout
                </>
              )}
            </Button>
          </div>
        </div>
      </ul>
    </nav>
  );
};

export default Menubar;
