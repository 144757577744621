import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
  Card,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ZoCallLog = () => {
  const userState = useSelector((state) => state.loginData.userData);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState("");
  const [totalCallCount, setTotalCallCount] = useState("");
  const [totalUsersCount, setTotalUsersCount] = useState("");
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    getAllUser();
  }, [date,startDate,endDate]);

  const getAllUser = async (val) => {
    setLoader(true);
    const payload = {
      zonekey: userState?.zonekey,
      callingDate: date,
      startDate: startDate,
      endDate: endDate,
    };
    await fetch(
      process.env.REACT_APP_BASE_URL + "/zonalOfficer/getTeleCallingUsers",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setUsers(response.data);
        setTotalUsersCount(response?.userCount);
        setTotalCallCount(response?.totalCallCount);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err while gettting users=>", err);
      });
  };

  return (
    <div>
      <Container>
        <Row className="justify-content-end">
          {/* <Col md={3} className="mb-3 mb-md-0">
            <Form.Label>Search by date</Form.Label>
            <Form.Control
              type="date"
              value={date}
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) =>
                setDate(moment(e.target.value).format("YYYY-MM-DD"))
              }
            />
          </Col> */}
          <Col md={2} className="mb-3 mb-md-0">
            <Form.Label>Search by Start Date</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) =>
                setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
              }
            />
          </Col>
          <Col md={2} className="mb-3 mb-md-0">
            <Form.Label>Search by End Date</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              min={startDate ? startDate : ""}
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) =>
                setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
              }
            />
          </Col>
        </Row>
        <Row>
          <div className="d-flex justify-content-center align-items-center mt-2 mb-1">
            <Icon.TelephoneForward className="me-2" size={20} color="#073c7c" />
            <h5 className="mb-0"> Call Log </h5>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-2 mb-1">
            <h6 className="mb-0" style={{ marginRight: "20px" }}>
              Total calls : {totalCallCount ? totalCallCount : 0}
            </h6>
            <h6 className="mb-0">
              Total users : {totalUsersCount ? totalUsersCount : 0}
            </h6>
          </div>
        </Row>

        <Row className="mt-3 mb-5">
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: "72vh" }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th style={{ width: "maxContent" }}>Name</th>
                    <th>Mobile No.</th>
                    <th>Zone</th>
                    <th> Gat</th>
                    <th>Role</th>
                    <th style={{ whiteSpace: "nowrap" }}>Calling Count</th>
                    <th>Talk Duration</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {users !== null && users !== undefined && users.length > 0
                      ? users.map((row, index) => {
                          return (
                            <tr
                              style={{ textAlign: "center", cursor: "pointer" }}
                            >
                              <td>{index + 1}</td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {row?.name}
                              </td>
                              <td>{row?.contactNo}</td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {row?.zonename}
                              </td>
                              <td
                                style={{
                                  maxWidth: "150px",
                                  wordWrap: "break-word",
                                }}
                              >
                                {row?.gatname.toString()}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {row?.usertype_name}
                              </td>
                              <td>{row?.callingCount}</td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {row?.talkDuration}
                              </td>
                              <td
                                onClick={() =>
                                  navigate("/dashboard/call-log-report", {
                                    state: row,
                                  })
                                }
                              >
                                <Icon.Eye size={22} />
                              </td>
                            </tr>
                          );
                        })
                      : "No call logs found"}
                  </tbody>
                )}
              </Table>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ZoCallLog;
