import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import MultibarChart from '../Charts/MultibarChart';
import GraphLoader from '../../Assets/GraphLoader.gif';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';

const JaptiKarvaiZoneReport = () => {

     const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)

    const [key, setKey] = useState("ReportTable");
    // Payment Type
    const [summaryLabels, setSummaryLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    // Payment Mode
    const [paymentModeLabel, setPaymentModeLabel] = useState([])
    const [paymentModeChartData, setPaymentModeChartData] = useState([])
    // Payment Later
    const [payLaterLabel, setPayLaterLabel] = useState([])
    const [payLaterChartData, setPayLaterChartData] = useState([])
    // Japti Karvahi
    const [JaptiKarvahiLabel, setJaptiKarvahiLabel] = useState([])
    const [JaptiKarvahiChartData, setJaptiKarvahiChartData] = useState([])
    // Amount Paid 
    const [AmountPaidLabel, setAmountPaidLabel] = useState([])
    const [AmountPaidChartData, setAmountPaidChartData] = useState([])
    // Japti Warrant Count 
    const [JaptiWarrantLabel, setJaptiWarrantLabel] = useState([])
    const [JaptiWarrantChartData, setJaptiWarrantChartData] = useState([])
    // Nal Connection 
    const [NalConnectionLabel, setNalConnectionLabel] = useState([])
    const [NalConnectionChartData, setNalConnectionChartData] = useState([])

    //Japti Karvahi Zone Report Table
    const [tableData, setTableData] = useState([])
    const [newTableData, setNewTableData] = useState([])
    const [selectDate, setSelectDate] = useState('')
    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    //Loader
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        getPaymentTypeGraph()
        getPaymentModeGraph()
        getPayLaterGraph()
        getJaptiKarvahiGraph()
        getAmountPaidGraph()
        getJaptiWarrantGraph()
        getTableData()
        getNalConnectionGraph()
    }, [])

    useEffect(()=>{
        getNewTableData()
    }, [selectDate || " "])

    const getPaymentTypeGraph = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/graph/zoneWisePaymentType', {
            method: 'GET'
        }).then((res) => res.json()).then((response) => {
            if (response.status === 200) {
                setSummaryLabels(response?.zones);

                let dataArray = [];

                dataArray.push({
                    type: 'bar',
                    label: "Part Payments",
                    fill: false,
                    data: response.partPayments,
                    borderWidth: 2,
                    borderColor: 'rgba(0, 218, 33, 1)',
                    backgroundColor: "rgba(28, 255, 68, 0.43)"

                });

                dataArray.push({
                    type: 'bar',
                    label: "Full Payments",
                    fill: false,
                    data: response.fullPayments,
                    borderWidth: 2,
                    borderColor: 'rgba(243, 0, 0, 1)',
                    backgroundColor: "rgba(255, 0, 0, 0.4)"
                });

                setChartData(dataArray);
            }
        }).catch((err) => {
            console.log("Err while getting call summary", err);
        })
    }

    const getPaymentModeGraph = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/graph/zoneWisePaymentMode', {
            method: 'GET'
        }).then((res) => res.json()).then((response) => {
            if (response.status === 200) {
                setPaymentModeLabel(response?.zones)

                let dataArray = [];

                dataArray.push({
                    type: 'bar',
                    label: "Online Payments",
                    fill: false,
                    data: response.onlineCount,
                    borderWidth: 2,
                    borderColor: 'rgba(0, 218, 33, 1)',
                    backgroundColor: "rgba(28, 255, 68, 0.43)"

                });

                dataArray.push({
                    type: 'bar',
                    label: "Cheque Payments",
                    fill: false,
                    data: response.chequeCount,
                    borderWidth: 2,
                    borderColor: 'rgba(153, 102, 255, 1)',
                    backgroundColor: "rgba(153, 102, 255, 0.2)"
                });

                dataArray.push({
                    type: 'bar',
                    label: "DD Payments",
                    fill: false,
                    data: response.ddCount,
                    borderWidth: 2,
                    borderColor: 'rgba(243, 0, 0, 1)',
                    backgroundColor: "rgba(255, 0, 0, 0.4)"
                });

                dataArray.push({
                    type: 'bar',
                    label: "Cash Payments",
                    fill: false,
                    data: response.cashCount,
                    borderWidth: 2,
                    borderColor: 'rgba(255, 159, 64, 1)',
                    backgroundColor: "rgba(255, 159, 64, 0.2)"
                });

                setPaymentModeChartData(dataArray);
            }
        }).catch((err) => {
            console.log("Err while getting call summary", err);
        })
    }

    const getPayLaterGraph = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/graph/zoneWisePayLater', {
            method: 'GET'
        }).then((res) => res.json()).then((response) => {
            if (response.status === 200) {
                setPayLaterLabel(response?.zones)

                let dataArray = [];

                dataArray.push({
                    type: 'bar',
                    label: "Pay Later",
                    fill: false,
                    data: response.payLaterCount,
                    borderWidth: 2,
                    borderColor: 'rgba(0, 218, 33, 1)',
                    backgroundColor: "rgba(28, 255, 68, 0.43)"

                });

                setPayLaterChartData(dataArray);
            }
        }).catch((err) => {
            console.log("Err while getting call summary", err);
        })
    }

    const getJaptiKarvahiGraph = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/graph/zoneWiseJaptiKarvahi', {
            method: 'GET'
        }).then((res) => res.json()).then((response) => {
            if (response.status === 200) {
                setJaptiKarvahiLabel(response?.zones)

                let dataArray = [];

                dataArray.push({
                    type: 'bar',
                    label: "Japti Karvahi",
                    fill: false,
                    data: response.japtiCount,
                    borderWidth: 2,
                    borderColor: 'rgba(0, 218, 33, 1)',
                    backgroundColor: "rgba(28, 255, 68, 0.43)"

                });

                setJaptiKarvahiChartData(dataArray);
            }
        }).catch((err) => {
            console.log("Err while getting call summary", err);
        })
    }

    const getAmountPaidGraph = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/graph/zoneWiseAmountPaid', {
            method: 'GET'
        }).then((res) => res.json()).then((response) => {
            if (response.status === 200) {
                setAmountPaidLabel(response?.zones)

                let dataArray = [];

                dataArray.push({
                    type: 'bar',
                    label: "Amount Paid",
                    fill: false,
                    data: response.totalAmount,
                    borderWidth: 2,
                    borderColor: 'rgba(0, 218, 33, 1)',
                    backgroundColor: "rgba(28, 255, 68, 0.43)"

                });

                setAmountPaidChartData(dataArray);
            }
        }).catch((err) => {
            console.log("Err while getting call summary", err);
        })
    }

    const getJaptiWarrantGraph = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/graph/zoneWiseJaptiWarrant', {
            method: 'GET'
        }).then((res) => res.json()).then((response) => {
            if (response.status === 200) {
                setJaptiWarrantLabel(response?.zones)

                let dataArray = [];

                dataArray.push({
                    type: 'bar',
                    label: "Japti Warrant",
                    fill: false,
                    data: response.japtiWarrantCount,
                    borderWidth: 2,
                    borderColor: 'rgba(0, 218, 33, 1)',
                    backgroundColor: "rgba(28, 255, 68, 0.43)"

                });

                setJaptiWarrantChartData(dataArray);
            }
        }).catch((err) => {
            console.log("Err while getting call summary", err);
        })
    }

    const getNalConnectionGraph = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/graph/zoneWiseNalConnection', {
            method: 'GET'
        }).then((res) => res.json()).then((response) => {
            if (response.status === 200) {
                setNalConnectionLabel(response?.zones)

                let dataArray = [];

                dataArray.push({
                    type: 'bar',
                    label: "Nal Connection Sealed",
                    fill: false,
                    data: response.japtiNalConnectionCount,
                    borderWidth: 2,
                    borderColor: 'rgba(0, 218, 33, 1)',
                    backgroundColor: "rgba(28, 255, 68, 0.43)"

                });

                setNalConnectionChartData(dataArray);
            }
        }).catch((err) => {
            console.log("Err while getting call summary", err);
        })
    }

    const getTableData = async () => {
        setLoader(true)
        await fetch(process.env.REACT_APP_BASE_URL + '/japtikarvahi/zoneWiseJaptiKarvahi', {
            method: 'GET'
        }).then((res) => res.json()).then((response) => {
            if (response.status === 200) {
                setLoader(false)
                setTableData(response?.data)
            }
        }).catch((err) => {
            console.log("Err while getting call summary", err);
            setLoader(false)
        })
    }

    const getNewTableData = async () =>{
        setLoader(true)
        await fetch(process.env.REACT_APP_BASE_URL + '/japtikarvahi/zoneWiseReport', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body:JSON.stringify({
                date : selectDate ? selectDate : "",
                financialYear : financialYear
            })
        }).then((res) => res.json()).then((response) => {
            console.log("new response", response);
            if (response.status === 200) {
                setLoader(false)
                setNewTableData(response?.data)
            }
        }).catch((err) => {
            console.log("Err while getting call summary", err);
            setLoader(false)
        })
    }

    function calculateTotal(columnKey) {
        if (newTableData && newTableData.length > 0) {
            return newTableData.reduce((total, row) => {
                return total + (row[columnKey] || 0);
            }, 0);
        } else {
            return 0;
        }
    }

    return (
        <div className='JaptiKarvaiZoneReport'>
            <ToastContainer />
            <Container>
                <div className='d-flex justify-content-center align-items-center '>
                    <Icon.CardHeading className='me-2' size={24} color='#073c7c' />
                    <h5 className='text-center p-0 mb-0'>Japti Karvahi Zone Report</h5>
                </div>
                <Row className='justify-content-center'>
                    <Col md={12} className='tab'>
                        <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                            <Row className="flex-column align-items-center">
                                <Col xs={12} className='d-flex justify-content-end p-0'>
                                    <Nav variant="pills" className="flex-row">
                                        <Nav.Item>
                                            <Nav.Link eventKey="ReportTable">Report Table</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ReportCharts">Report Charts</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>

                                <Row className=" d-flex justify-content-between align-items-center">
                                    <Col md={3} className="mb-3 mb-md-0">
                                    <div className="d-flex">
                                    <Form.Control
                                        type="date"
                                        value={selectDate}
                                        max={new Date().toISOString().split("T")[0]}
                                        onChange={(e) =>
                                            setSelectDate(moment(e.target.value).format("YYYY-MM-DD"))
                                        }
                                        />
                                        <Button className="btn btn-submit ms-2" onClick={() =>setSelectDate("")}>
                                        <Icon.ArrowClockwise className="me-2" size={14} />
                                        Reset
                                        </Button>
                                    </div>
                                    </Col>
                                </Row>

                                <Col className='mt-4'>
                                    <Tab.Content>

                                        <Tab.Pane eventKey="ReportTable">

                                            {/* <div className="tbl-container p-0">
                                                <div className="tbl-fixed p-0" style={{ maxHeight: '73vh' }}>
                                                    <Table responsive="lg" hover border={1}>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>Zone</th>
                                                                <th>Japti Warrant Count</th>
                                                                <th>Japti Count</th>
                                                                <th>Pay Now Count</th>
                                                                <th>Part Payment Count</th>
                                                                <th>Full Payment Count</th>
                                                                <th>Online Payment Count</th>
                                                                <th>Cheque Payment Count</th>
                                                                <th>DD Payment Count</th>
                                                                <th>Cash Payment Count</th>
                                                                <th>Pay Later Count</th>
                                                                <th>Total Amount</th>
                                                                <th>Japti Nal Connection Sealed</th>
                                                            </tr>
                                                        </thead>

                                                        {loader ? (
                                                            <div className='d-flex justify-content-center align-items-center h-100 w-100'>
                                                                <img src={GraphLoader} alt="Loader" width={200} />
                                                            </div>
                                                        ) : (
                                                            <tbody>
                                                                {tableData !== null && tableData !== undefined && tableData.length > 0
                                                                    ? tableData.map((row, index) => {
                                                                        return (
                                                                            <tr
                                                                                style={{ textAlign: "center", cursor: "pointer" }}>
                                                                                <td>{row?.zone}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.japtiWarrantCount}</td>
                                                                                <td>{row?.japtiCount}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.partCount}</td>
                                                                                <td >{row?.fullCount}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.onlineCount}</td>
                                                                                <td>{row?.chequeCount}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.ddCount}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.cashCount}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.payLaterCount}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.totalAmount}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.japtiNalConnectionCount}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                    : "No Data found"}
                                                            </tbody>
                                                        )}
                                                    </Table>
                                                </div>
                                            </div> */}

                                            <div className="tbl-container p-0">
                                                <div className="tbl-fixed p-0" style={{ maxHeight: '73vh' }}>
                                                    <Table responsive="lg" hover border={1}>
                                                        <thead>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <th>Sr.No.</th>
                                                                <th>Zone</th>
                                                                <th>Japti Warrant Count</th>
                                                                <th>Japti Count</th>
                                                                <th>Pay Now Count</th>
                                                                <th>Pay Later Count</th>
                                                                <th>Total Amount</th>
                                                                <th>Japti Nal Connection Sealed</th>
                                                            </tr>
                                                        </thead>

                                                        {loader ? (
                                                            <Spinner variant="primary" className="mt-2" />
                                                        ) : (

                                                            <>
                                                            <tbody>
                                                                {newTableData !== null && newTableData !== undefined && newTableData.length > 0
                                                                    ? newTableData.map((row, index) => {
                                                                        return (
                                                                            <tr
                                                                                style={{ textAlign: "center", cursor: "pointer" }}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{row?.zone}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.japtiWarrantCount}</td>
                                                                                <td>{row?.japtiCount}</td>
                                                                                <td>{row?.payNowCount}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.payLaterCount}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.totalAmount}</td>
                                                                                <td style={{ whiteSpace: 'nowrap' }}>{row?.japtiNalConnectionCount}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                    : "No Data found"}
                                                            </tbody>

                                                            <tfoot>
                                                                <tr>
                                                                    <th>Total</th>
                                                                    <td></td>
                                                                    <td><strong>{calculateTotal('japtiWarrantCount')}</strong></td>
                                                                    <td><strong>{calculateTotal('japtiCount')}</strong></td>
                                                                    <td><strong>{calculateTotal('payNowCount')}</strong></td>
                                                                    <td><strong>{calculateTotal('payLaterCount')}</strong></td>
                                                                    <td><strong>{calculateTotal('totalAmount')}</strong></td>
                                                                    <td><strong>{calculateTotal('japtiNalConnectionCount')}</strong></td>
                                                                </tr>
                                                            </tfoot>
                                                            </>
                                                        )}
                                                     
                                                    </Table>
                                                </div>
                                            </div>

                                        </Tab.Pane>

                                        <Tab.Pane eventKey="ReportCharts">
                                            {chartData.length > 0 ?
                                                <div className='mt-2'>
                                                    <MultibarChart chartLabels={summaryLabels} chartData={chartData} stacked={true} chartHeading="ZoneWise Payment Type" />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src={GraphLoader} alt="Loader" width={200} />
                                                </div>
                                            }

                                            {paymentModeChartData.length > 0 ?
                                                <div className='mt-4'>
                                                    <MultibarChart chartLabels={paymentModeLabel} chartData={paymentModeChartData} stacked={true} chartHeading="ZoneWise Payment Mode" />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src={GraphLoader} alt="Loader" width={200} />
                                                </div>
                                            }

                                            {payLaterChartData.length > 0 ?
                                                <div className='mt-4'>
                                                    <MultibarChart chartLabels={payLaterLabel} chartData={payLaterChartData} stacked={true} chartHeading="ZoneWise Pay Later" />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src={GraphLoader} alt="Loader" width={200} />
                                                </div>
                                            }

                                            {JaptiKarvahiChartData.length > 0 ?
                                                <div className='mt-4'>
                                                    <MultibarChart chartLabels={JaptiKarvahiLabel} chartData={JaptiKarvahiChartData} stacked={true} chartHeading="ZoneWise Japti Karvahi" />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src={GraphLoader} alt="Loader" width={200} />
                                                </div>
                                            }

                                            {AmountPaidChartData.length > 0 ?
                                                <div className='mt-4'>
                                                    <MultibarChart chartLabels={AmountPaidLabel} chartData={AmountPaidChartData} stacked={true} chartHeading="ZoneWise Amount Paid" />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src={GraphLoader} alt="Loader" width={200} />
                                                </div>
                                            }

                                            {JaptiWarrantChartData.length > 0 ?
                                                <div className='mt-4'>
                                                    <MultibarChart chartLabels={JaptiWarrantLabel} chartData={JaptiWarrantChartData} stacked={true} chartHeading="ZoneWise Japti Warrant Count" />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src={GraphLoader} alt="Loader" width={200} />
                                                </div>
                                            }

                                            {NalConnectionChartData.length > 0 ?
                                                <div className='mt-4'>
                                                    <MultibarChart chartLabels={NalConnectionLabel} chartData={NalConnectionChartData} stacked={true} chartHeading="ZoneWise Nal Connection Sealed Count" />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center h-100'>
                                                    <img src={GraphLoader} alt="Loader" width={200} />
                                                </div>
                                            }

                                        </Tab.Pane>

                                    </Tab.Content>
                                </Col>
                            </Row>

                        </Tab.Container>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default JaptiKarvaiZoneReport