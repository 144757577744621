import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row,Col, Card, Button } from "react-bootstrap";
import * as Icon  from "react-bootstrap-icons";

const ViewDeletedReport = () => {
  const location = useLocation();
  // const VisitId = location?.state?.report;
  const VisitId = JSON.parse(localStorage.getItem('report'));
  const [visitData, setVisitData] = useState(VisitId);

  return (
    <div className="visitReport">
      <Row className="mb-2 mt-4">
        <h4 className="mb-0 text-center">
          <Icon.FileText className="me-1" /> Deleted Report 
        </h4>
      </Row>
      <Row className="mb-2 mt-4">
          <Col md={2}>
            <Button className="backButton" onClick={ () => window.history.back() }>
             <Icon.ArrowLeft size={20} className="me-2" /> Back
            </Button>
          </Col>
      </Row>
        <Card className="deleteCard">
            <Card.Header>
                <Card.Title>Bill Delete Details</Card.Title>
            </Card.Header>
            <Card.Body>
                <b>Reason :- </b><span>{visitData?.mavimDeleteReason}</span>
            </Card.Body>
            <Card.Footer  className="d-flex justify-content-between">
                <span>
                    <b>Mavim Name :- </b>{visitData?.mavimDeleteName}
                </span>
                <span>
                    <b>Mavim Mobile :- </b>{visitData?.mavimDeleteContactNo}
                </span>
            </Card.Footer>
        </Card>
      <Row className="outer-wrapper mx-auto mt-4 mb-4">
        <div className="table-wrapper">
          <table data-aos="fade-up" data-aos-delay="200">
            <thead>
              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Code
                </th>
                <td>
                  {visitData?.propertyCode ? visitData?.propertyCode : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Owner Name
                </th>
                <td>
                  {visitData?.propertyName ? visitData?.propertyName : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Owner Mobile
                </th>
                <td>
                  {visitData?.mobileUpdated ? visitData?.mobileUpdated : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Address
                </th>
                <td style={{ wordWrap:'break-word',wordBreak:'break-all' }}>
                  {visitData?.propertyAddress ? visitData?.propertyAddress : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Category Name
                </th>
                <td>
                  {visitData?.propertyCategoryName ? visitData?.propertyCategoryName : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Property Image
                </th>
                <td>
                  {visitData?.propertyImg ? <a href={visitData?.propertyImg} target="_blank">
                    <img src={visitData?.propertyImg} style={{ width:80,height:80 }} />
                  </a> : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Gat Name
                </th>
                <td>
                  {visitData?.gatname ? visitData?.gatname : "-"}
                </td>
              </tr>


              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Zone Name
                </th>
                <td>
                  {visitData?.zonename ? visitData?.zonename : "-"}
                </td>
              </tr>


              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Visit Person Name
                </th>
                <td>
                  {visitData?.visitingPersonName ? visitData?.visitingPersonName : "-"}
                </td>
              </tr>


              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Visit Person Mobile
                </th>
                <td>
                  {visitData?.visitingPersonContactNo ? visitData?.visitingPersonContactNo : "-"}
                </td>
              </tr>

              <tr>
                <th>
                  <Icon.Code className="me-2" />
                  Upyog Karta Shulka Name
                </th>
                <td>
                  {visitData?.upayogakartaShulkName ? visitData?.upayogakartaShulkName : "-"}
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </Row>
    </div>
  );
};

export default ViewDeletedReport;
