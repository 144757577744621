import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import ScrollToTop from './Components/Common/ScrollToTop';
import Home from './Components/Common/Home';
import CommonLayout from './Components/Layout/CommonLayout';
import Login from './Feature/Login/Login';
import Contractor from './Components/Admin/Contractor';
import Visits from './Components/Admin/Visits';
import ManageRoles from './Components/Admin/ManageRoles';
import Distribution from './Components/Admin/Distribution';
import ZonalContractorReport from './Components/ZonalOfficer/ZonalContractorReport';
import ZonalDistribution from './Components/ZonalOfficer/ZonalDistribution';
import ZonalDistributionHistory from './Components/ZonalOfficer/ZonalDistributionHistory';
import BillAnalysis from './Components/Admin/BillAnalysis';
import TargetReport from './Components/ZonalOfficer/TargetReport';
import ZoneWiseConReport from './Components/Admin/ZoneWiseConReport';
import CallSummary from './Components/Admin/CallSummary';
import CallLog from './Components/Admin/CallLog';
import CallLogReport from './Components/Admin/CallLogReport';
import JaptiKarvai from './Components/Admin/JaptiKarvai';
import JaptiNotice from './Components/Admin/JaptiNotice';
import JaptiMukti from './Components/Admin/JaptiMukti';
import ContactRecords from './Components/Admin/ContactRecords';
import InternalDev from './Components/InternalDev/InternalDev';

import PageNotFound from './Components/Common/PageNotFound';
import { useSelector } from 'react-redux';
import JaptiKarvaiZoneReport from './Components/Admin/JaptiKarvaiZoneReport';
import DialedCallLog from './Components/Admin/DialedCallLog';
import DialedCallLogReport from './Components/Admin/DialedCallLogReport';
import TotalCalling from './Components/Admin/TotalCalling';
import ZOCallSummary from './Components/ZonalOfficer/ZoCallSummary';
import ZoHome from './Components/ZonalOfficer/ZoHome';
import ZoJaptiNotice from './Components/ZonalOfficer/ZoJaptiNotice';
import SmsSentReport from './Components/Admin/SmsSentReport';
import SmsDetails from './Components/Admin/SmsDetails';
import BillDistribution from './Components/Common/BillDistribution';
import ZoCallLog from './Components/ZonalOfficer/ZoCallLog';
import ZoDialedCallLog from './Components/ZonalOfficer/ZoDialedCallLog';
import ZoTotalCalling from './Components/ZonalOfficer/ZoTotalCalling';
import ZoCallSummary from './Components/ZonalOfficer/ZoCallSummary';
import ZoJaptiKarvai from './Components/ZonalOfficer/ZoJaptiKarvai';
import ZoJaptiMukti from './Components/ZonalOfficer/ZoJaptiMukti';
import PropertyCalling from './Components/Caller/PropertyCalling';
import NewHome from './Components/NewDashboard/NewHome'
import NewContractor from './Components/NewDashboard/NewContractor';
import NewVisitReport from './Components/NewDashboard/NewVisitReport';
import ViewVisitReport from './Components/NewDashboard/ViewVisitReport';
import DeleteUsers from './Components/Common/DeleteUsers';
import BillReports from './Components/NewDashboard/BillReports';
import DeleteBillHistory from './Components/NewDashboard/DeleteBillHistory';
import ViewDeletedReport from './Components/NewDashboard/ViewDeletedReport';
import PrivacyPolicy from './Components/Common/PrivacyPolicy';
import NewBillAnalysis from './Components/NewDashboard/NewBillAnalysis';
import ViewContractorReport from './Components/NewDashboard/ViewContractorReport';
import NewJaptiNotice from './Components/NewDashboard/NewJaptiNotice';
import RejectedFormList from './Components/NewDashboard/RejectedFormList';
import NewGatWiseBillAnalysis from './Components/NewDashboard/NewGatWiseBillAnalysis';
import Analytics from './Components/Admin/Analytics';
import ViewFullScreen from './Components/Common/ViewFullScreen';
import InternalDevNew from './Components/InternalDev/InternalDevNew';


function App() {

  const userState = useSelector(state => state?.loginData?.userData);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/delete/users' element={<DeleteUsers />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/view-fullscreen' element={<ViewFullScreen />} />
          {
            userState?.usertype_keyname?.includes("caller") &&
            <Route path="/property-calling" element={<PropertyCalling />} />
          }

          {userState?.usertype_keyname?.[0] === "admin" || userState?.usertype_keyname?.[0] === "internalDev" ?
            <Route path='/dashboard' element={<CommonLayout />}>
              <Route path='/dashboard' element={<Home />} />
              <Route path='/dashboard/analytics' element={<Analytics />} />
              <Route path='/dashboard/bill-distribution' element={<BillDistribution />} />
              <Route path='/dashboard/manage-roles' element={<ManageRoles />} />
              <Route path='/dashboard/contractors' element={<Contractor />} />
              <Route path='/dashboard/visits' element={<Visits />} />
              <Route path='/dashboard/distribution' element={<Distribution />} />
              <Route path='/dashboard/zonewise-bill-analysis' element={<BillAnalysis />} />

              <Route path='/dashboard/call-summary' element={<CallSummary />} />
              <Route path='/dashboard/call-logs' element={<CallLog />} />
              <Route path='/dashboard/dialed-call-logs' element={<DialedCallLog />} />
              <Route path='/dashboard/call-log-report' element={<CallLogReport />} />
              <Route path='/dashboard/dialed-call-log-report' element={<DialedCallLogReport />} />
              <Route path='/dashboard/japti-karvai' element={<JaptiKarvai />} />
              <Route path='/dashboard/japti-notice' element={<JaptiNotice />} />
              <Route path='/dashboard/japti-mukti' element={<JaptiMukti />} />
              <Route path='/dashboard/japti-karvai-zone-report' element={<JaptiKarvaiZoneReport />} />
              <Route path='/dashboard/update-mobile' element={<ContactRecords />} />
              <Route path='/dashboard/total-calling' element={<TotalCalling />} />
              <Route path='/dashboard/sms-sent-report' element={<SmsSentReport />} />
              <Route path='/dashboard/sms-details' element={<SmsDetails />} />

              {userState?.usertype_keyname?.[0] === "internalDev" &&
                <>
                  <Route path='/dashboard/internal-dev' element={<InternalDev />} />
                  <Route path='/dashboard/internal-dev-New' element={<InternalDevNew />} />
                </>

              }

              <Route path='/dashboard/new-home' element={<NewHome />} />
              <Route path='/dashboard/new-contractors' element={<NewContractor />} />
              <Route path='/dashboard/new-visit-reports' element={<NewVisitReport />} />
              <Route path='/dashboard/view-visit-report' element={<ViewVisitReport />} />
              <Route path='/dashboard/view-contractor-report' element={<ViewContractorReport />} />
              <Route path='/dashboard/bill-report' element={<BillReports />} />
              <Route path='/dashboard/deleted-bill-history' element={<DeleteBillHistory />} />
              <Route path='/dashboard/view-delete-report' element={<ViewDeletedReport />} />
              <Route path='/dashboard/new-bill-analysis' element={<NewBillAnalysis />} />
              <Route path='/dashboard/new-japti-notice' element={<NewJaptiNotice />} />
              <Route path='/dashboard/rejected-list' element={<RejectedFormList />} />
              <Route path='/dashboard/gatwise-bill-analysis' element={<NewGatWiseBillAnalysis />} />

            </Route>
            : ''
          }

          {
            userState?.usertype_keyname?.[0] === "zonalofficer" &&
            <Route path='/dashboard' element={<CommonLayout />}>
              <Route path='/dashboard' element={<ZoHome />} />
              <Route path='/dashboard/zonal-contractors' element={<ZonalContractorReport />} />
              <Route path='/dashboard/zonal-distribution' element={<ZonalDistribution />} />
              <Route path='/dashboard/zonal-distribution-history' element={<ZonalDistributionHistory />} />
              <Route path='/dashboard/zonal-target' element={<TargetReport />} />
              <Route path='/dashboard/zonal-contractor-report' element={<ZoneWiseConReport />} />
              <Route path='/dashboard/zonal-japti-notice' element={<ZoJaptiNotice />} />

              {/* manage telecalling */}
              <Route path='/dashboard/zonal-call-summary' element={<ZoCallSummary />} />
              <Route path='/dashboard/zonal-call-logs' element={<ZoCallLog />} />
              <Route path='/dashboard/call-log-report' element={<CallLogReport />} />
              <Route path='/dashboard/zonal-dialed-call-logs' element={<ZoDialedCallLog />} />
              <Route path='/dashboard/dialed-call-log-report' element={<DialedCallLogReport />} />
              <Route path='/dashboard/zonal-total-calling' element={<ZoTotalCalling />} />

              {/* japti details */}
              <Route path='/dashboard/zonal-japti-notice' element={<ZoJaptiNotice />} />
              <Route path='/dashboard/zonal-japti-karvai' element={<ZoJaptiKarvai />} />
              <Route path='/dashboard/zonal-japti-mukti' element={<ZoJaptiMukti />} />

              <Route path='/dashboard/new-home' element={<NewHome />} />
              <Route path='/dashboard/manage-roles' element={<ManageRoles />} />
              <Route path='/dashboard/new-contractors' element={<NewContractor />} />
              <Route path='/dashboard/new-visit-reports' element={<NewVisitReport />} />
              <Route path='/dashboard/view-visit-report' element={<ViewVisitReport />} />
              <Route path='/dashboard/bill-report' element={<BillReports />} />
              <Route path='/dashboard/deleted-bill-history' element={<DeleteBillHistory />} />
              <Route path='/dashboard/view-delete-report' element={<ViewDeletedReport />} />
              <Route path='/dashboard/new-japti-notice' element={<NewJaptiNotice />} />
              <Route path='/dashboard/new-bill-analysis' element={<NewBillAnalysis />} />
              <Route path='/dashboard/gatwise-bill-analysis' element={<NewGatWiseBillAnalysis />} />

            </Route>

          }

          {
            userState?.usertype_keyname?.[0] === "supervisor" &&
            <Route path='/dashboard' element={<CommonLayout />}>
              <Route path='/dashboard/manage-roles' element={<ManageRoles />} />
            </Route>
          }

          {
            userState?.usertype_keyname?.[0] === "mavim" ?
              <Route path='/dashboard' element={<CommonLayout />}>
                <Route path='/dashboard/new-home' element={<NewHome />} />
                <Route path='/dashboard/manage-roles' element={<ManageRoles />} />
                <Route path='/dashboard/new-contractors' element={<NewContractor />} />
                <Route path='/dashboard/new-visit-reports' element={<NewVisitReport />} />
                <Route path='/dashboard/view-visit-report' element={<ViewVisitReport />} />
                <Route path='/dashboard/bill-report' element={<BillReports />} />
                <Route path='/dashboard/deleted-bill-history' element={<DeleteBillHistory />} />
                <Route path='/dashboard/view-delete-report' element={<ViewDeletedReport />} />
                <Route path='/dashboard/view-contractor-report' element={<ViewContractorReport />} />
                <Route path='/dashboard/new-japti-notice' element={<NewJaptiNotice />} />
                <Route path='/dashboard/rejected-list' element={<RejectedFormList />} />

              </Route>
              : ''
          }


          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
