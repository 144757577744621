import { useEffect, useState } from 'react';


const useAllZoneGat = () => {

    const [zoneList, setZoneList] = useState([]);
    const [gatList, setGatList] = useState([])
    const [allGatList, setAllGatList] = useState([]);
    const [filteredGats,  setFilteredGats] = useState([]);

    useEffect(()=>{
        getZoneList();
        getGatList();
    },[])

    const getZoneList=async()=>{
        await fetch(process.env.REACT_APP_BASE_URL + "/zoneData/getZoneData", {
            method: "GET",
          })
            .then((res) => res.json()).then((response) => {
              setZoneList(response?.data?.sort((a, b) => a.zoneno-b.zoneno).map((itm) => {
                return ({ value: itm.zonekey, label: itm.zonename + "-" + itm.zoneno })
              }));
            })
            .catch((err) => {
              console.log("Error While Fetching Zone List", err);
            });
    }

    
    const getGatList=async()=>{
        await fetch(process.env.REACT_APP_BASE_URL + "/gatData/getGatData", {
          method: "POST",
          headers:{'Content-Type':'application/json'},
          body:JSON.stringify({zonekey: zoneList?.value})
          })
            .then((res) => res.json()).then((response) => {
              setGatList(response.data)
              setAllGatList(response?.data?.map((itm) => {
                return ({ value: itm, label:itm.gatname })
              }));

              setFilteredGats(response?.data?.map((itm) => {
                return ({ value: itm.gatkey, label:itm.gatname })
              }));
            })
            .catch((err) => {
              console.log("Error While Fetching Zone List", err);
            });
    }


  return {  zoneList, allGatList, setAllGatList, filteredGats, setFilteredGats,gatList,setGatList,getGatList }
}

export default useAllZoneGat;