import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, FloatingLabel, Button, Table, Spinner, Modal, Nav, Tab } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from 'xlsx';
import editBar from "../../Assets/EditPencilBar.svg";
import Delete from "../../Assets/Delete.svg";
import Download from '../../Assets/Download.svg';
import { useSelector } from "react-redux";
import useAllZoneGat from "../Common/useAllZoneGat";
import Select from "react-select";

const ManageRoles = () => {

  const userState = useSelector(state => state.loginData.userData);

  const { zoneList } = useAllZoneGat();

  // Variables for Setting Role, Zone & Gat DATA -------------
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [pass, setPass] = useState("");
    const [zone, setZone] = useState('');
    const [gat, setGat] = useState(null);
    const [role, setRole] = useState('');
    const [editZone, setEditZone] = useState("");
    const [editGat, setEditGat] = useState(null);
    const [userId, setUserId] = useState("");
    const [key, setKey] = useState("editUser");


  // Error varaibles for add user validation-----------
    const [nameErr, setNameErr] = useState(false);
    const [mobileErr, setMobileErr] = useState(false);
    const [passErr, setPassErr] = useState(false);
    const [roleErr, setRoleErr] = useState(false);
    const [zoneErr, setZoneErr] = useState(false);
    const [gatErr, setGatErr] = useState(false);

  let contractorRole = [{
    value: 'contractor', label: 'Contractor'
  }]

  const [userType, setUserType] = useState([]);

  // Delete variables ------------
  const [deleteRowData, setDeleteRowData] = useState();

  const [users, setUsers] = useState("");
  const [allUserState, setAllUserState] = useState('');

  // State for Loaders ---------
  const [loader, setLoader] = useState(false); // loader at users table
  const [btnLoader, setBtnLoader] = useState(false); // loader at add user modal

  // State for modal show/hide -------------------------------
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [gatList, setGatList] = useState([])
  const [allGatList, setAllGatList] = useState([]);
  const [filteredGats, setFilteredGats] = useState([]);

  useEffect(() => {
    getAllUserTypes();
    getUsers();
  }, []);

  const getGatData = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/gatData/getGatData", {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ zonekey: editZone ? editZone?.value : zone?.value })
    })
      .then((res) => res.json()).then((response) => {
        setGatList(response.data)
        setAllGatList(response?.data?.map((itm) => {
          return ({ value: itm, label: itm.gatname })
        }));

        setFilteredGats(response?.data?.map((itm) => {
          return ({ value: itm.gatkey, label: itm.gatname })
        }));
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  }

  const gatOption = gatList !== undefined && gatList !== null && gatList.length > 0
    ? gatList.map((itm) => ({
      label: itm.gatname,
      value: itm.gatkey,
    }))
    : [];

  useEffect(() => {
    if (zone?.value || editZone?.value) {
      getGatData()
    }
  }, [zone?.value || editZone?.value]);


  const getAllUserTypes = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/usertype/getallusertypes", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setUserType(response?.data?.map((itm) => {
          return ({ value: itm.keyname, label: itm.name })
        }))
      })
      .catch((err) => {
        console.log("Error While Fetching userType", err);
      });
  };

  const validate = () => {

    let formValid = true;
    if (name?.length === 0) {
      formValid = false;
      setNameErr(true);
    }
    else if (mobile?.length !== 10) {
      formValid = false;
      setMobileErr(true);
    }
    else if (pass?.length === 0) {
      formValid = false;
      setPassErr(true);
    }
    else if (zone?.length === 0) {
      formValid = false;
      setZoneErr(true);
    }
    else if (gat?.length === 0 || gat === null || gat === undefined) {
      formValid = false;
      setGatErr(true);
    }
    else if (userState.usertype_keyname[0] == "admin") {
      if (role?.length === 0) {
        formValid = false;
        setRoleErr(true);
      }
    }
    return formValid;
  };

  // Adding Users --------------------
  const addUser = async (e) => {
    e.preventDefault();
    if (validate()) {
      setBtnLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + "/user/addUser2", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: name.trim(),
          contactNo: mobile,
          password: pass,
          zonekey: zone.value,
          gatkey: gat?.map((itm) => itm?.value),
          usertype_keyname: role?.value,
          createdBy: userState._id,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === "User added successfully") {
            setBtnLoader(false);
            toast.success("User added successfully");
            getUsers();
            setShowAdd(false);
            closeModal();
          } else if (response.message === "User already exists") {
            setBtnLoader(false);
            toast.error("Sorry, user mobile already exists");
          } else if (response.status === 500) {
            setBtnLoader(false);
            toast.error("Failed to add, try again");
          }
         
        })
        .catch((err) => {
          console.log("Error while adding user", err);
        });
    } else {
      setBtnLoader(false);
    }
  };


  // Fetching Users ---------------
    const getUsers = async () => {
        setLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + "/user/usertypeusers", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          usertype_keyname : userState?.usertype_keyname[0] === "admin" || userState?.usertype_keyname[0]==="internalDev" ? 
          ["hod", "zonalofficer", "zonalstaff", "contractor", "supervisor", "gatlipik", "internalDev", "caller"]
            : ["contractor"] 
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          setUsers(response?.data);
          setAllUserState(response?.data);
          let arr = [];
          arr.length = response.data.length
          setLoaderArr(arr)
          setLoader(false);
        })
        .catch((err) => {
          console.log("Error while fetching users", err);
          setLoader(false);
        });
    };

 // Delete Users -------------------------------------
  const handledelete = async () => {
    let obj = {
      user_id: deleteRowData?._id,
      updatedBy: userState._id,
    };

    setBtnLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/user/deleteuser", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
        else {
          toast.error("Error while deleting");
          setShowDelete(false);
        }
      })
      .then((response) => {

        if (response.message === "Deleted successfully") {
          setBtnLoader(false);
          toast.success("User Deleted Successfully");
          getUsers();
          setShowDelete(false);
        } else {
          setBtnLoader(false);
          toast.error("Error while deleting");
          setShowDelete(false);
        }
    
      })
      .catch((err) => {
        console.log("Error while adding user", err);
      });
  };


  // ----------------- EDIT USER ------------------------

  //  Edit variables ------

  // const [gatId, setGatId] = useState({ gat_id: "" });
  // const [zoneId, setZoneId] = useState({ zone_id: "" });

  // useEffect(() => {
  //   getFiltertedEditGat();
  //   setGat("");
  // }, [editZone]);

  // const getFiltertedEditGat = () => {
  //   console.log("EditZone at filter", editZone);
  //   const result = allGatList.filter((row) => row.zonekey == editZone.zonekey);
  //   setFilterGat(result);
  //   console.log("result-----------125", result);
  // };

  const openEdit = (row) => {
    setUserId(row._id);
    setName(row.name);
    setEditZone({ value: row.zonekey, label: row.zonename + "-" + row.zoneno });
    let gatArray = [];
    for (let i = 0; i < row.gatkey.length; i++) {
      gatArray.push({
        value: row?.gatkey[i], label: row?.gatname[i]
      })

    }
    setEditGat(gatArray);
    setMobile(row.contactNo);
    setRole(row?.usertype_name[0]);
    setShowEdit(true);
  };

  const EditValidate = () => {

    let editValid = true;
    if (name?.length === 0) {
      editValid = false;
      setNameErr(true);
    }
    if (editZone.length === 0) {
      editValid = false;
      setZoneErr(true);
    }
    else if (editGat.length === 0) {
      editValid = false;
      setGatErr(true);
    }
    return editValid;
  };


  const editUser = async () => {
    if (EditValidate()) {
      await fetch(process.env.REACT_APP_BASE_URL + "/user/updateUser2", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: userId,
          name: name,
          zonekey: editZone.value,
          gatkey: editGat?.map((itm) => itm?.value.toString()),
          updateBy: userState?._id
        })
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === "User Updated Successfully") {
            toast.success("User details updated successfully");
            closeModal();
            getUsers();
          }
          else {
            toast.error("Error while updating, try again");
            closeModal();
          }
        })
        .catch((err) => {
          console.log("Err while Updating user-", err);
        });
    }
  };

  // ------------- UPDATE PASSWORD ----------------------
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [newPassErr, setNewPassErr] = useState(false);
  const [confirmPassErr, setConfirmPassErr] = useState(false);
  const [passMatchErr, setPassMatchErr] = useState(false);


  const passValidate = () => {
    let passValid = true;
    if (newPass.length === 0) {
      passValid = false;
      setNewPassErr(true);
    }
    if (confirmPass.length === 0) {
      passValid = false;
      setConfirmPassErr(true);
    }
    if (newPass !== confirmPass) {
      passValid = false;
      setPassMatchErr(true);
    }
    return passValid;
  }

  const updatePass = async () => {
    if (passValidate()) {
      await fetch(process.env.REACT_APP_BASE_URL + '/user/updatepassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          _id: userId,
          password: newPass
        })
      }).then((res) => res.json()).then((response) => {
        if (response.message === "Password updated successfully") {
          toast.success("User Password updated successfully");
        }
      }).catch((err) => {
        console.log("Err while Updating pass", err);
      })

    }
    // await fetch(process.env.REACT_APP_BASE_URL + '/user/updatepassword',{
    //   method:'POST',
    //   headers:{'Content-Type':'application/json'},
    //   body:JSON.stringify({
    //     id:userId,
    //     password:newPass})
    // });

  }

  const closeModal = () => {
    // Resetting Modal input fields to emptty ----------
    setName("");
    setMobile("");
    setPass("");
    setZone("");
    setGat("");
    setRole("");

    setNewPass("");
    setConfirmPass("")

    // Resetting alll error messages -----------
    setNameErr(false);
    setMobileErr(false);
    setPassErr(false);
    setZoneErr(false);
    setGatErr(false);
    setRoleErr(false);

    setNewPassErr(false);
    setConfirmPassErr(false);
    setPassMatchErr(false);
    setKey("editUser");
    setShowAdd(false);
    setShowEdit(false);
    setBtnLoader(false);
  };

  const [sheetLoader, setSheetLoader] = useState(false);
  const [loaderArr, setLoaderArr] = useState([])

  const CustomExcel = async (row, index) => {
    setSheetLoader(true);
    let arr = loaderArr
    arr[index] = true
    setLoaderArr(arr)
    await fetch(process.env.REACT_APP_BASE_URL + '/property/propertycodeofcontractor', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ visitingPersonContactNo: row.contactNo })
    }).then((res) => res.json())
      .then((response) => {
        if (response.count === 0) {
          toast.error("No Visits Found to export");
          setSheetLoader(false);
          let arr = loaderArr
          arr[index] = false
          setLoaderArr(arr);
        }
        else {

          const heading = []
          let maxlen = Math.max(response.data1.length, response.data2.length);

          if (response)
            for (let i = 0; i < maxlen; i++) {
              let obj = {
                "property_found": response.data2[i] ? response.data2[i] : '-',
                "property_not_found": response.data1[i] ? response.data1[i] : '-'
              }
              heading.push(obj)
            }
          downloadExcel(heading, index)

          //   const customHeadings1 = response.count > 0 && response.data1.map((row) => ({
          //     "Property Found": row,
          // }))
          // console.log("customHeadings1", customHeadings1);
          // console.log("is Array CustomHeading 1", Array.isArray(customHeadings1));

          // const customHeadings2 = response.count > 0 && response.data2.map((row) => ({
          //   "Property Not Found": row
          // }))
          // console.log("customHeadings2", customHeadings2);


          //   downloadExcel(customHeadings1, customHeadings2index);
        }
      }).catch((err) => {
        console.log("Err while Export Excel =>", err);
        setSheetLoader(false);
      })
  };

  const downloadExcel = (heading, index) => {
    let dataSet = [];
    dataSet = heading;
    const worksheet = XLSX.utils.json_to_sheet(dataSet);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Contractor Visits.xlsx");
    setSheetLoader(false);
    let arr = loaderArr;
    arr[index] = false;
    setLoaderArr(arr);
  };


  // ----- searching user with mobile number -------
  const [searchMobile, setSearchMobile] = useState('');
  const [searchMobErr, setSearchMobErr] = useState(false);
  const searchByMobile = () => {
    if (searchMobile.length < 10) {
      setSearchMobErr(true);
    }
    else {
      const result = Array.isArray(allUserState) && allUserState.length > 0 && allUserState.filter((row) => row.contactNo === searchMobile);
      setUsers(result);
    }
  }
  

  return (
    <div className="manageRoles mt-1">
      <ToastContainer />
      <Container>
        <Row className=" d-flex justify-content-between  align-items-center">
          <Col md={3} className="mb-3 mb-md-0">
            <div className="d-flex">
              <Form.Control placeholder="mobile number" value={searchMobile} className="me-2" onChange={(e) => {
                if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10) {
                  setSearchMobile(e.target.value);
                }
                e.target.value.length >= 0
                  ? setSearchMobErr(false)
                  : setSearchMobErr(true);
              }} />
              <Button className="btn-submit" onClick={searchByMobile}>
                  <Icon.Search size={14} className="me-2"/>Search</Button>
            </div>
            {searchMobErr ? <p>Please Enter Valid Mobile Number</p> : null}
          </Col>
          <Col md={4} className="d-flex justify-content-end">
            <Button className="btn-submit" onClick={() => { setShowAdd(true); }}>
              <Icon.PlusCircle className="me-2" /> Add User
            </Button>
          </Col>

        </Row>

        {/* -------- Fetching User Data --------- */}
        <Row className="mt-4">
          <div className="d-flex align-items-center mb-2">
            <Icon.PersonVcard size={24} className="me-2" />
            <h5 className="mb-0">User Details</h5>
          </div>


          <div className="tbl-container p-0">
            <div className="tbl-fixed p-0" style={{ maxHeight: '73vh' }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th>Sr. No.</th>
                    <th>Officer's Name</th>
                    <th>Mobile Number</th>
                    <th>Role</th>
                    <th>Zone</th>
                    <th>Gat</th>
                    <th>Update Actions</th>
                    <th>Excel</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (<center><Spinner /></center>
                  ) : Array.isArray(users) && users.length > 0 ? (
                    users.map((row, index) => {
                      return (
                        <tr style={{ textAlign: "center" }}>
                          <td style={{ width: "6%" }}>{index + 1}</td>
                          <td style={{ width: "20%" }}>{row?.name}</td>
                          <td>{row?.contactNo}</td>
                          <td>{row?.usertype_name[0]}</td>
                          <td>{row?.zonename + "-" + row.zoneno}</td>
                          <td style={{ maxWidth: '150px', wordWrap: 'break-word' }}>{row?.gatname.toString()}</td>
                          <td style={{ width: "15%" }}>
                            <div className="d-flex justify-content-center text-center align-items-center">
                              <img src={editBar} alt="EditBar" className="me-2 me-md-4" width="24px" height="24px" onClick={() => { openEdit(row) }} />
                              <img src={Delete} alt="Delete" width="24px" height="24px" onClick={() => { setDeleteRowData(row); setShowDelete(true); }} />
                            </div>
                          </td>
                          <td>{loaderArr[index] ? <Spinner /> : <img src={Download} alt="Download" width="19px" height="19px" className="m-auto" onClick={() => CustomExcel(row, index)} />}</td>
                        </tr>
                      );
                    })
                  ) : (
                    "No user data found"
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </Container>


{/* ----------- Add Modal ---------------- */}
      <Modal dialogClassName="modal" show={showAdd} onHide={closeModal} centered backdrop="static" keyboard={false} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <img src={editBar} alt="Add" />
            <h5 className="mb-0">Add User Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={addUser}>
          <Modal.Body className="px-4 py-4">
            <Row className="gy-3 addUser">
              {/* <h4 className="mb-0">Add User</h4> */}
              <Col md={6}>
                <Form.Group>
                  <Form.Label><span>*</span>Name</Form.Label>
                  <Form.Control placeholder="Name" name="name" value={name}
                    onChange={(e) => {
                      if (!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\//\-/?<>,;`'""/]/) &&
                        e.target.value.trim()
                      ) {
                        setName(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setName(e.target.value);
                      }
                      name.length >= 0 ? setNameErr(false) : setNameErr(true);
                    }}
                  />
                </Form.Group>
                {nameErr ? <p>Please enter valid name</p> : null}
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label><span>*</span>Mobile Number</Form.Label>
                  <Form.Control placeholder="Mobile Number" value={mobile} name="contactNo"
                    onChange={(e) => {
                      if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) &&
                        e.target.value.length <= 10
                      ) {
                        setMobile(e.target.value);
                      }
                      mobile.length >= 0 ? setMobileErr(false) : setMobileErr(true);
                    }}
                  />
                  {mobileErr ? <p> Please enter valid mobile number </p> : null}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Label><span>*</span>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" name="password"
                  value={pass} onChange={(e) => {
                    if (!e.target.value.match(/[/\s/g/]/)) {
                      setPass(e.target.value);
                    }
                    pass.length >= 0 ? setPassErr(false) : setPassErr(true);
                  }}
                />
                {passErr ? <p>Please enter password</p> : null}
              </Col>

              <Col md={6}>
                <Form.Label><span>* </span>Zone</Form.Label>
                <Select isMulti={false} isSearchable={false} value={zone}
                  options={zoneList} onChange={(option) => {
                    setZone(option);
                    setGat(null)
                    setZoneErr(false);
                  }} />
                {zoneErr ? <p>Please select any zone</p> : null}
              </Col>

              <Col md={6}>
                <Form.Label><span>*</span>Gat</Form.Label>
                <Select value={gat} isMulti={true} isSearchable={false} isDisabled={!zone ? true : false}
                  options={gatOption} onChange={(option) => {
                    setGat(option); 
                    setGatErr(false);
                  }} />
                {gatErr ? <p>Please select any gat</p> : null}
              </Col>

              <Col md={6}>
                <Form.Label><span>*</span>Role</Form.Label>
                {
                  userState?.usertype_keyname[0] !== 'supervisor' ?
                    <Select isMulti={false} isSearchable={false} value={role}
                      options={userType} onChange={(option) => {
                        setRole(option);
                        role.length >= 0 ? setRoleErr(false) : setRoleErr(true);
                      }} />
                    :
                    <Select isMulti={false} isSearchable={false} value={role}
                      options={contractorRole} onChange={(option) => {
                        setRole(option);
                        role.length >= 0 ? setRoleErr(false) : setRoleErr(true);
                      }} />
                }


                {/* <Form.Select
                  onChange={(e) => {
                    console.log("Role", JSON.parse(e.target.value));
                    setRole(JSON.parse(e.target.value));
                    e.target.value.length > 0
                      ? setRoleErr(false)
                      : setRoleErr(true);
                  }}>
                  {userState.usertype_keyname[0] == "supervisor" ? (
                    <option selected disabled>
                      Contractor
                    </option>
                  ) : userType.length > 0 &&
                    userType !== undefined &&
                    userType !== null ? (
                    <>
                      <option value="" selected disabled>
                        Select any role
                      </option>
                      {userType.map((row) => {
                        return (
                          <option key={row._id} value={JSON.stringify(row)}>
                            {row.name}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <option value="" disabled>
                      No Role Found
                    </option>
                  )}
                </Form.Select> */}
                {roleErr ? <p>Please select any role</p> : null}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {btnLoader ? (<Spinner variant="primary" />) : (
              <Button className="btn btn-submit" type="submit">
                <Icon.PlusCircle className="me-2" /> Add User
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>


      {/* ----------- Edit Modal ----------------*/}
      <Modal dialogClassName="modal" show={showEdit} onHide={closeModal} centered backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <img src={editBar} alt="Add" />
            <h5 className="mb-0">Update User</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 py-4">
          <Row className="addUser">
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Row className="flex-column align-items-center m-0 p-0">
                <Col xs={12} className='d-flex justify-content-center'>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="editUser">Update Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="updatePass">Update Password</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>

                <Col className='mt-4'>
                  <Tab.Content>
                    <Tab.Pane eventKey="editUser">

                      <Row className="gy-3 justify-content-center">
                        <Col md={4}>
                          <Form.Label><span>*</span>Name</Form.Label>
                          <Form.Control placeholder="Name" name="name" value={name} onChange={(e) => {
                            if (!e.target.value.match(/[0-9+@#$&%!~=^_:(){}\[\]|/*./\/g/\-/?<>,;`'""\\/]/) &&
                              e.target.value.trim()) {
                              setName(e.target.value);
                            } else if (e.target.value.length === 0) {
                              setName(e.target.value);
                            }
                          }}
                          />
                          {nameErr ? <p>Please enter valid name</p> : null}
                        </Col>

                        <Col md={4}>
                          <Form.Label><span>*</span>Mobile Number</Form.Label>
                          <Form.Control placeholder="Mobile Number" name="mobile" value={mobile} disabled />
                        </Col>

                        <Col md={4}>
                          <Form.Label><span>*</span>Zone</Form.Label>
                          <Select isMulti={false} isSearchable={false} value={editZone}
                            options={zoneList} onChange={(option) => {
                              setEditZone(option);
                              setEditGat(null)
                              editZone.length <= 0 ? setZoneErr(true) : setZoneErr(false);
                            }} />
                          {zoneErr ? <p>Please select any zone</p> : null}
                        </Col>

                        <Col md={4}>
                          <Form.Label><span>*</span>Gats</Form.Label>
                          <Select value={editGat} isMulti={true} isSearchable={false}
                            options={gatOption} onChange={(option) => {
                              setEditGat(option);
                              editGat?.length <= 0 ? setZoneErr(true) : setZoneErr(false);
                            }} />
                          {gatErr ? <p>Please select any gat</p> : null}
                        </Col>


                        <Col md={4}>
                          <Form.Label><span>*</span>Role</Form.Label>
                          <Form.Control placeholder="Role" name="role" value={role} disabled />
                        </Col>


                        <div className="text-center mt-4">
                          {btnLoader ? (
                            <Spinner variant="primary" />
                          ) : (
                            <Button className="btn-submit mx-auto" onClick={editUser}>
                              <Icon.PencilSquare className="me-2" /> Submit Details
                            </Button>
                          )}
                        </div>
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="updatePass">
                      <Row className="justify-content-center">
                        <Col md={4} className="mb-3 mb-md-0">
                          <Form.Label><span>*</span>New Password</Form.Label>
                          <Form.Control placeholder="New Password" name="newPass" onChange={(e) => setNewPass(e.target.value)} />
                          {newPassErr ? <p>Please enter valid new password</p> : ""}
                        </Col>

                        <Col md={4}>
                          <Form.Label><span>*</span>Confirm Password</Form.Label>
                          <Form.Control placeholder="Confirm Password" name="confirmPass" onChange={(e) => setConfirmPass(e.target.value)} />
                          {confirmPassErr ? <p>Please enter valid confirm password</p> : ""}
                        </Col>

                        <div className="text-center mt-4">

                          <Button className="btn-submit mx-auto" onClick={updatePass}><Icon.LockFill className="me-2" />Submit Password</Button>
                          {passMatchErr ? <p>Sorry, New & Confirm Password doesn't match</p> : ""}

                        </div>
                      </Row>

                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={closeModal}>
            <Icon.XLg className="me-2" />
            Cancel
          </Button>
          {/* {btnLoader ? (
            <Spinner variant="primary" />
          ) : (
            <Button className="btn btn-submit" onClick={editUser}>
              <Icon.PencilSquare className="me-2" /> Update
            </Button>
          )} */}
        </Modal.Footer>
      </Modal>

      {/* ----------- Delete Modal ----------------*/}

      <Modal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        centered
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title><Icon.Trash className="me-2" />Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="text-center">
            <h4>Are you sure to delete</h4>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={() => setShowDelete(false)}>
            <Icon.XLg className="me-2" />
            Cancel
          </Button>
          <Button className="btn-submit" onClick={() => handledelete()}>
            <Icon.Trash3 className="me-2" /> Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageRoles;
