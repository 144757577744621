import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import { Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import PCMC from '../../Assets/PCMC-LOGO.png';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../../Feature/Login/LoginSlice';
import { setActiveKey } from '../../Feature/Sidebar/ActiveKeySlice'; 
import { setLabelDate, setLabelMonth } from '../../Feature/Graph/label';
import Refresh from '../../Assets/Refresh.svg';


const CallerNav = () => {

  const userState = useSelector(state => state.loginData.userData);


  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute('data-visible');
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = 'hidden';
    }
    else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = 'auto';
    }
  }


  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout(null));
    dispatch(setActiveKey(0));
    dispatch(setLabelDate(''));
    dispatch(setLabelMonth(''));
    navigate('/');
    setLoader(false);
  }

  const entering = (e) => {
    e.children[0].style.borderTopColor = 'white';
    e.children[1].style.backgroundColor = 'white';
    e.children[0].style.borderRadius = '12px';
    e.children[1].style.borderRadius = '12px';
  };

  const Popup = (
    <Popover>
      <Popover.Body>
        <div className='PopupData'>
          <div>
            <Icon.Person className='me-1' size={18} /><p><strong>Name - </strong>{userState?.name}</p>
          </div>
          <div>
            <Icon.ShieldLock className='me-1' size={17} /><p><strong>Role - </strong>{userState?.usertype_name[0]}</p>
          </div>
          <div>
            <Icon.Telephone className='me-2' size={14} /><p><strong>Mobile - </strong>{userState?.contactNo}</p>
          </div>
          <div>
            <Icon.Bullseye className='me-2' size={15} /><p><strong>Zone - </strong>{userState?.zonename}</p>
          </div>
          <div>
            {userState?.isActive ? <Icon.ToggleOn className='me-2' size={15} /> : <Icon.ToggleOff className='me-2' size={15} />}
            <p><strong>Status - </strong>{userState?.isActive ? "Active" : "InActive"}</p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  )



  return (

    <nav className='CallerNav'>
        <div className='d-flex align-items-center'>
            <img src={PCMC} alt="PCMC Logo" width={40}/>
            <h5 className='mb-0 ms-2 d-none d-md-block'>PCMC</h5>
        </div>

      <ul>
        <OverlayTrigger key='bottom' placement='bottom' overlay={Popup}
          onEntering={entering} style={{width:'maxContent'}}>
          <div className='d-flex align-items-center' style={{ border: 'none' }}>
            <Icon.PersonFill className='me-2' size={28} />
              <div>
                <p style={{ fontWeight: '600', fontSize: '15px', marginBottom: '-4px' }}>{userState?.name}</p>
                <p style={{ fontSize: '12px', marginBottom: '0px' }}>{userState?.usertype_name[0]}</p>
              </div>
              <Button className='btn-submit ms-3' onClick={handleLogout}>
                    {loader ? <Spinner animation="border" variant="light" className='spinner' /> 
                    : <><Icon.BoxArrowLeft className='me-2' />Logout</>}
                </Button> 
            </div>
        </OverlayTrigger>
      </ul>

   

    </nav>
  )
}

export default CallerNav;