import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';

const ZoneWiseConReport = () => {


  const [zones, setZones] = useState('');
  const [zoneNo, setZoneNo] = useState('7');
  const [data, setData] = useState('');
  const [distinctSum, setDistinctSum] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getZoneList();
    getZoneWiseConReport();
  }, [])

  useEffect(() => {
    getZoneWiseConReport();
  }, [zoneNo])


  // Function for Fetching ZONE LIST --------------------
  const getZoneList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/zoneData/getZoneData`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setZones(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };


  const getZoneWiseConReport = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/visitreport/reportofcontractorzone', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ zone: zoneNo })
    }).then((res) => res.json()).then((response) => {
      setData(response.data);
      setLoader(false);
      setDistinctSum(response.data.reduce((total, obj) => total + obj.districtC, 0));
    }
    ).catch((err) => {
      console.log("Err while getting ZoneWiseConReport", err);
      setLoader(false);
    })
  }

  // const sum = data!==undefined && data.length>0 && data.reduce((total, obj) => total + obj.districtC, 0);

  // const [filterResult, setFilterResult] = useState('');

  // const filterData=(zone)=>{
  //     console.log("Zone at filter", zone);
  //     const result= data!==undefined && data.length>0 ? data.filter((row)=>row.zone===zone) : '';
  //     console.log("Result==>", result);
  //     setFilterResult(result);
  // }

  const [rightActive, setRightActive] = useState();
  const [leftActive, setLeftActive] = useState();

  const [activeTab, setActiveTab] = useState('पिंपरी नगर');

  const ref = useRef(null);

  const scroll = (scrollOffset, direction) => {

    if (direction === "left") {
      ref.current.scrollLeft -= scrollOffset;
    }
    if (direction === "right") {
      ref.current.scrollLeft += scrollOffset;
    }

    if (ref.current.scrollLeft >= 5) {
      setLeftActive(true);
    }
    else {
      setLeftActive(false);
    }

    let maxScrollValue = ref.current.scrollWidth - ref.current.clientWidth - 20;
    if (ref.current.scrollLeft >= maxScrollValue) {
      setRightActive(true);
    }
    else {
      setRightActive(false);
    }

  }

  
  return (
    <div className='zonWiseConReport noselect'>

      <Container>
        <Row>
          <div className='d-flex justify-content-center align-items-center mb-4'>
            <Icon.PersonVcard className='me-2' size={24} />
            <h5 className='mb-0'>Zone Wise Connection Report</h5>
          </div>
        </Row>


        <>
          <Row className='justify-content-center'>
            <Col md={10}>
              <div className='scrollable-tabs-container'>
                <div className={leftActive ? 'left-arrow active' : 'left-arrow'} >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" onClick={() => scroll(300, "left")}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                  </svg>
                </div>

                <ul ref={ref}>
                  {
                    zones !== undefined && zones.length > 0 ? zones.map((row, index) => {
                      return (
                        <li key={index + 1} className={activeTab === row.zonename ? 'active' : ''} onClick={() => { setZoneNo(row.zoneno); setActiveTab(row.zonename) }}>{row.zonename}</li>
                      )
                    }) : ''
                  }
                </ul>

                <div className={rightActive ? 'right-arrow' : 'right-arrow active'}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" onClick={() => scroll(300, "right")}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </div>

              </div>
            </Col>
          </Row>

          <Row className='mt-3'>
            <div className="container tbl-container p-0">
              <div className="row tbl-fixed p-0" style={{ maxHeight: '70vh' }}>
                <Table responsive="lg" hover>
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th style={{ width: 'maxContent' }}>Name</th>
                      <th>Mobile Number</th>
                      <th>Working Start Day</th>
                      <th>Distinct Count</th>
                    </tr>
                  </thead>

                  {loader ? (
                    <Spinner variant="primary" className="mt-2" />
                  ) : (
                    <tbody>
                      {data !== null && data !== undefined && data.length > 0
                        ? data.map((row, index) => {
                          return (
                            <tr style={{ textAlign: 'center' }} key={index + 1}>
                              <td>{index + 1}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{row?.name}</td>
                              <td>{row?.contactNo}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{moment(row?.workingStartDay).format("DD-MM-YYYY")}</td>
                              <td>{row?.districtC}</td>
                            </tr>
                          );
                        })
                        : "No report data found"}
                    </tbody>
                  )}
                  {
                    <tfoot>
                      <tr>
                        <th>Total</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{loader ? '' : distinctSum}</td>
                      </tr>
                    </tfoot>}
                </Table>
              </div>
            </div>

          </Row>
        </>

      </Container>

    </div>
  )
}

export default ZoneWiseConReport;









//     const scrollLeft=(scrollOffset)=>{
//         ref.current.scrollLeft -= scrollOffset;
//     }

// const scrollRight = (scrollOffset) => {
//     ref.current.scrollLeft += scrollOffset;

//     if(ref.current.scrollLeft >=5){
//         setArrowActive(true);
//     }
//     else{
//         setArrowActive(false);
//     }
//   };


// const tabs = document.querySelectorAll('.zoneWiseConReport .scrollable-tabs-container a');


// const removeAllActiveClasses=()=>{
//     tabs.forEach((tab)=>{
//         tab.classList.remove('active');
//     })
// }

// tabs.forEach(tab=>{
//     tab.addEventListener('click', () => {
//         removeAllActiveClasses();
//         tab.classList.add('active');
//     })
// })

// rightArrow.addEventListener('click', ()=>{
//     tabList.scrollLeft += 200;
// })


{/* <li> <a href='#' className='active'>मनपा भवन</a> </li>
                            <li> <a href='#' >फ़ुगेवाडी दापोडी</a> </li>
                            <li> <a href='#' >निगडी प्राधिकरण</a> </li>
                            <li> <a href='#' >निगडी प्राधिकरण</a> </li>
                            <li> <a href='#' >निगडी प्राधिकरण</a> </li>
                            <li> <a href='#' >दिघी बोपखेल</a> </li>
                            <li> <a href='#' >वाकड</a> </li>
                            <li> <a href='#' >पिंपरी वाघेरे</a> </li>
                            <li> <a href='#' >पिंपरी वाघेरे</a> </li>
                            <li> <a href='#' >चिखली</a> </li>
                            <li> <a href='#' >वाकड</a> </li>
                            <li> <a href='#' >वाकड</a> </li>
                            <li> <a href='#' >पिंपरी वाघेरे</a> </li>
                            <li> <a href='#' >पिंपरी वाघेरे</a> </li>
                            <li> <a href='#' >चिखली</a> </li>
                            <li> <a href='#' >वाकड</a> </li>
                            <li> <a href='#' >वाकड</a> </li> */}
{/* <Tabs defaultActiveKey="profile"  id="uncontrolled-tab-example" className="mb-3 d-flex" style={{whiteSpace:'nowrap'}} >
                        <Tab eventKey="home" title="पिंपरी नगर">
                            <h3>Content Home</h3>
                        </Tab>
                        <Tab eventKey="profile" title="मनपा भवन">
                            <h3>Content Profile</h3>
                        </Tab>
                        <Tab eventKey="contact" title="फ़ुगेवाडी दापोडी">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="सांगवी" title="सांगवी">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="थेरगाव" title="थेरगाव">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="चिखली" title="चिखली">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="किवळे" title="किवळे">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="चऱ्होली" title="चऱ्होली">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="पिंपरी वाघेरे" title="पिंपरी वाघेरे">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="चिंचवड" title="चिंचवड">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="निगडी प्राधिकरण" title="निगडी प्राधिकरण">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="आकुर्डी" title="आकुर्डी">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="दिघी बोपखेल" title="दिघी बोपखेल">
                            <h3>Content Other</h3>
                        </Tab>
                        <Tab eventKey="निगडी प्राधिकरण" title="वाकड">
                            <h3>Content Other</h3>
                        </Tab>
                    </Tabs> */}