import React, { useEffect, useState } from "react";
import Chart from 'chart.js/auto';
import { Bar } from "react-chartjs-2";
// import { Card, Spinner, Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import ChartModal from "./ChartModal";
import { setLabelMonth, setLabelDate } from "../../Feature/Graph/label";
import GraphLoader from '../../Assets/GraphLoader.gif';
import { ToastContainer, toast } from "react-toastify";
import ChartModal from "./ChartModal";
import ChartDataLabels from 'chartjs-plugin-datalabels';



const BarChart = ({ indexAxis, chartLabels, chartLabel, ColorArray, BorderArray, chartData, handleDateLoader, dateParam }) => {
  const [loader, setLoader] = useState(false);


  useEffect(() => {
    if (chartData !== undefined && chartData !== null && chartData.length > 0) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [chartData]);


  const [show, setShow] = useState(false);

  const dispatch = useDispatch();


  const getLabel = (dataLabel, label) => {
    if (dataLabel === "Month Counts") {
      dispatch(setLabelMonth(label));

    }
    if (dataLabel === "Date Counts") {
      dispatch(setLabelDate(label));
      handleDateLoader()
    }

    if (dataLabel === "Month Counts" && dateParam == "date") {
      handleDateLoader()
    }

    if (dataLabel === "Date Counts" && dateParam == "hours") {
      handleDateLoader()
    }
  }

  // const noIndexFound =()=>toast.error("Invalid click, Please click on valid bar");

  /* ----- Charts Props for sending ClickEvent Data to Common Modal ------ */
  const [label, setLabel] = useState();
  const [dataLabel, setDataLabel] = useState();
  const [value, setValue] = useState();


  return (
    <>
      <ToastContainer />
      {/* <Card className="ChartCard" style={{ height:"250px", maxHeight: "250px", padding: "25px 15px 15px 15px" }}> */}
      {/* <h6>{chartHeading}</h6> */}
      {loader ? (
        <center>
          <img src={GraphLoader} width="150" maxHeight="250px" />
        </center>
      ) : (

        <Bar
          style={{ padding: '0px 10px 0px 10px' }}
          data={{
            labels: chartLabels,
            fill: true,
            datasets: [
              {
                label: chartLabel,
                fill: true,
                indexAxis: indexAxis,
                data: chartData,
                backgroundColor: ColorArray,
                borderColor: BorderArray,
                borderWidth: 2
              },

            ],
          }}

          plugins={[ChartDataLabels, { align: "end" }]}
          options={{
            plugins: {
              datalabels: {
                display: true,
                color: '#808080',
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start",
                font: {
                  weight: '500'
                }
              }

            },
            legend: {
              display: false
            },
            maintainAspectRatio: false,
            responsive: true,
            onClick: (e, element) => {

             if(element[0] !== undefined){
              let arr = [];
              e.chart.config._config.data.datasets.map((row) => {
                arr.push(row.data[element[0].index]);
              });
              // handleDateLoader()
              // handleHoursLoader()

              setLabel(e.chart.data.labels[element[0].index]);
              setDataLabel(e.chart.config._config.data.datasets[element[0].datasetIndex].label);
              setValue(arr[element[0].datasetIndex]);
              setShow(true);


              getLabel(e.chart.config._config.data.datasets[element[0].datasetIndex].label, e.chart.data.labels[element[0].index]);
             }
            },
          }}
        />



      )}
      {/* </Card> */}

      <ChartModal open={show} close={() => setShow(false)} data={{ label, dataLabel, value }} />
    </>
  );
};

export default BarChart;
