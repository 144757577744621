import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer } from "react-toastify";
import Pagination from "../Common/Pagination";
import moment from "moment";

const NewBillAnalysis = () => {
  // Variables for Zone-Gat Visitors Data ------------
  const [contractor, setContractor] = useState();
  const [TodayData,setTodayData] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateErr, setStartDateErr] = useState("");
  const [endDateErr, setEndDateErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [currentloader, setCurrentLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState(0);

  // function for pagination

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  //   useEffect API Calls
  useEffect(() => {
    getBillDistributionReport();
    getCurrentBillDistributionReport()
  }, []);

  // get List API
  const getBillDistributionReport = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/billDistribution/zoneWiseReport",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fromDate: startDate,
          toDate: endDate,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setContractor(response);
          setNoOfPages(response.noOfPages);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const getCurrentBillDistributionReport = async () => {
    setCurrentLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/billDistribution/zoneWiseReport",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fromDate: moment(new Date()).format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setTodayData(response);
          setNoOfPages(response.noOfPages);
          setCurrentLoader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setCurrentLoader(false);
      });
  };

  const searchByStartEndValidate = () => {
    let isValid = true;
    if (!startDate) {
      isValid = false;
      setStartDateErr(true);
    } else if (!endDate) {
      isValid = false;
      setEndDateErr(true);
    }
    return isValid;
  };

  // const searchByDate = async () => {
  //   if (searchByStartEndValidate()) {
  //     await getBillDistributionReport();
  //   }
  // };

  return (
    <div className="visitReport">
      <ToastContainer />
      <Container>
        <Row className="visitors mt-2">
          {/* <Col md={6} className="searchCard justify-content-center mb-4">
            <div className="label mb-2 mx-auto">
              <p>Search by Start Date/End Date</p>
            </div>
            <Row className="d-flex justify-content-center">
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Search by Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                    setEndDate("");
                    e.target.value.length > 0 && setStartDateErr(false);
                  }}
                />
                {startDateErr ? (
                  <p className="errMsg">Please select Start Date</p>
                ) : (
                  ""
                )}
              </Col>

              <Col md={4} className="mb-3 mb-md-0">
                <Form.Label>Search by End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  min={startDate ? startDate : ""}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                    e.target.value.length > 0 && setEndDateErr(false);
                  }}
                />
                {endDateErr ? (
                  <p className="errMsg">Please select End Date</p>
                ) : (
                  ""
                )}
              </Col>

              <Col md={2} className="mt-4">
                <Button className="btn btn-submit" onClick={searchByDate}>
                  <Icon.Search className="me-2" size={14} />
                  Search
                </Button>
              </Col>
            </Row>
          </Col> */}

          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: "65vh" }}>
              <Table responsive="lg" hover style={{ borderCollapse:'collapse' }}>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th colSpan="4" className="text-center border-th">
                      Current Year
                    </th>
                    <th colSpan="4" className="text-center">
                      Today Count
                    </th>
                  </tr>
                  <tr>
                    <th>Sr No.</th>
                    <th>Zone Name</th>
                    <th>Notice Count</th>
                    {/* <th>Meeting Person Count</th> */}
                    <th>Mobile Verified Count</th>
                    <th>Property Not Found Count</th>
                    <th className="border-th">Bill Issued Count</th>
                    {/* <th>Property Not Found Count</th>
                    <th>Total Count</th> */}
                    {/* <th>Action</th> */}
                    <th>Notice Count</th>
                    <th>Mobile Verified Count</th>
                    <th>Property Not Found Count</th>
                    <th>Bill Issued Count</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <center>
                      <Spinner />
                    </center>
                  ) : (
                    contractor && contractor?.data !== undefined && contractor?.data !== null &&
                    contractor?.data?.length > 0 &&
                    contractor?.data?.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td className="border-tr-new">
                            {pageNo !== 1 ? (
                              <> {index + 1 + docPerPage * (pageNo - 1)}</>
                            ) : (
                              <>{index + 1}</>
                            )}
                          </td>
                          <td className="border-tr-new">{row?.zone ? row?.zone : "-"}</td>
                          <td className="border-tr-new">{row?.noticeCount ? row?.noticeCount : 0}</td>
                          
                          {/* <td>{row?.meetingPersonCount ? row?.meetingPersonCount : 0 }</td> */}
                          <td className="border-tr-new">
                            {row?.mobileVerifiedCount
                              ? row?.mobileVerifiedCount
                              : 0}
                          </td>
                          <td className="border-tr-new">{row?.propertyNotFoundCount ? row?.propertyNotFoundCount : 0 }</td>
                          <td className="border-tr">
                            {row?.propertyFoundCount
                              ? row?.propertyFoundCount
                              : 0}
                          </td>
                          {/* <td>{row?.propertyNotFoundCount ? row?.propertyNotFoundCount : 0 }</td>
                            <td>{row?.totalCount ? row?.totalCount : 0 }</td> */}
                          {/* <td>Action here</td> */}
                          <td className="border-tr-new">{TodayData?.data[index]?.noticeCount ? TodayData?.data[index]?.noticeCount : 0}</td>
                          <td className="border-tr-new">
                            {TodayData?.data[index]?.mobileVerifiedCount
                              ? TodayData?.data[index]?.mobileVerifiedCount
                              : 0}
                          </td>
                          <td className="border-tr-new">{TodayData?.data[index]?.propertyNotFoundCount ? TodayData?.data[index]?.propertyNotFoundCount : 0 }</td>
                          <td>
                            {TodayData?.data[index]?.propertyFoundCount
                              ? TodayData?.data[index]?.propertyFoundCount
                              : 0}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
                <tfoot>
                  {contractor !== undefined && contractor?.data?.length > 0 ? (
                    <tr className="sum-row">
                      <td colSpan="2">
                        <strong className="m-2">Total Counts :-</strong>
                      </td>
                      <td className="border-th-new">
                        <strong>
                          {contractor && contractor.totalNoticeCount !== undefined && contractor.totalNoticeCount !== null 
                            ? contractor.totalNoticeCount
                            : "0"}
                        </strong>
                      </td>
                      {/* <td>
                        <strong>{contractor.totalMeetingPersonCount ? contractor.totalMeetingPersonCount : '0'}</strong>
                      </td> */}
                      <td className="border-th-new"> 
                        <strong>
                          {contractor && contractor.totalMobileVerifiedCount !== undefined && contractor.totalMobileVerifiedCount !== null 
                            ? contractor.totalMobileVerifiedCount
                            : "0"}
                        </strong>
                      </td>
                      <td className="border-th-new"> 
                        <strong>
                          {contractor && contractor.totalPropertyNotFoundCount !== undefined && contractor.totalPropertyNotFoundCount !== null 
                            ? contractor.totalPropertyNotFoundCount
                            : "0"}
                        </strong>
                      </td>
                      <td className="border-th">
                        <strong>
                          {contractor && contractor.totalPropertyFoundCount !== undefined && contractor.totalPropertyFoundCount !== null 
                            ? contractor.totalPropertyFoundCount
                            : "0"}
                        </strong>
                      </td>
                      {/* <td>
                        <strong>{contractor.totalPropertyNotFoundCount ? contractor.totalPropertyNotFoundCount : '0'}</strong>
                      </td>
                      <td>
                        <strong>{contractor.totalVisits ? contractor.totalVisits : '0'}</strong>
                      </td> */}

                      <td className="border-th-new">
                        <strong>
                          { TodayData && TodayData?.totalNoticeCount !== undefined && TodayData?.totalNoticeCount !== null  
                            ? TodayData?.totalNoticeCount
                            : "0"}
                        </strong>
                      </td>
                      <td className="border-th-new">
                        <strong>
                          { TodayData && TodayData?.totalMobileVerifiedCount !== undefined && TodayData.totalMobileVerifiedCount !== null  
                            ? TodayData.totalMobileVerifiedCount
                            : "0"}
                        </strong>
                      </td>
                      <td className="border-th-new">
                        <strong>
                          { TodayData && TodayData.totalPropertyNotFoundCount !== undefined && TodayData.totalPropertyNotFoundCount !== null  
                            ? TodayData.totalPropertyNotFoundCount
                            : "0"}
                        </strong>
                      </td>
                      <td className="border-th-new"> 
                        <strong>
                          {TodayData && TodayData.totalPropertyFoundCount !== undefined && TodayData.totalPropertyFoundCount !== null 
                            ? TodayData.totalPropertyFoundCount
                            : "0"}
                        </strong>
                      </td>
                    </tr>
                  ) : null}
                </tfoot>
              </Table>
            </div>
            {/* <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-start mb-0">
                    <Icon.People className="me-2" />
                    Total - <strong>{contractor?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row> */}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default NewBillAnalysis;
