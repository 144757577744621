import React from 'react';
import PowerBIFrame from '../Common/PowerBIFrame';
import NoRightClickDiv from '../Common/NoRightClick';
import { Col, Container, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

const Analytics = () => {

    const powerBIUrl1 = 'https://app.powerbi.com/view?r=eyJrIjoiZWU5MjAxZWMtOTlmOS00MzlmLWE1MTAtOTI2ODZhYzg3NThmIiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9';
    const powerBIUrl2 = 'https://app.powerbi.com/view?r=eyJrIjoiODJiZDI1YWItZmYyMS00ODNiLWExYTQtZmRmZjdhNDY0NTc2IiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9';
    const powerBIUrl3 = 'https://app.powerbi.com/view?r=eyJrIjoiMWE0MDUyMWQtMmQ2NS00YjQ4LWE3OGUtMjg5MDg0NTc5ZmIxIiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9';
    const powerBIUrl4 = 'https://app.powerbi.com/view?r=eyJrIjoiNmYyZjc5ODMtODY2ZC00OTBiLThjMzQtNTFkNmEwNTY4ZWVmIiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9';
    const powerBIUrl5 = 'https://app.powerbi.com/view?r=eyJrIjoiYjlmODk3OGQtZWZhZS00MWJjLTkxMjUtMjViNWU1NWE0ODFmIiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9';
    const powerBIUrl6 = 'https://app.powerbi.com/view?r=eyJrIjoiY2U1ODYwMTYtYWYzOS00ZTQyLWJiYmEtNGQwYzM5NDU4MTAyIiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9';
     
    const handleViewFullscreen = (url) => {
      sessionStorage.setItem('iframeUrl', url);
      window.open('/view-fullscreen', '_blank');
    };


  return (
    // <NoRightClickDiv>
      <div className='Analytics pt-5'>
        <Container>
          <Row>
            <Col md={12} className='mb-5 frame'>
              <PowerBIFrame src={powerBIUrl1} height="600px"/>
                <div className='frameFooter'>
                  <h5>Ptax Zone History</h5>
                  <p className='mb-0' onClick={()=>handleViewFullscreen(powerBIUrl1)}>
                    <Icon.ArrowsFullscreen className='me-2'/>View Fullscreen
                  </p>
                </div>
            </Col>

            <Col md={12} className='mb-5 frame'>
              <PowerBIFrame src={powerBIUrl2} height="600px"/>
              <div className='frameFooter'>
                <h5>Ptax-Siddhi 2.0 Analytics</h5>
                <p className='mb-0' onClick={()=>handleViewFullscreen(powerBIUrl2)}>
                  <Icon.ArrowsFullscreen className='me-2'/>View Fullscreen
                </p>
              </div>
            </Col>

            <Col md={12} className='mb-5 frame'>
              <PowerBIFrame src={powerBIUrl3} height="600px"/>
               <div className='frameFooter'>
                <h5>Ptax-Cricket Dashboard</h5>
                <p className='mb-0' onClick={()=>handleViewFullscreen(powerBIUrl3)}>
                  <Icon.ArrowsFullscreen className='me-2'/>View Fullscreen
                </p>
              </div>
            </Col>
            <Col md={12} className='mb-5 frame'>
              <PowerBIFrame src={powerBIUrl4} height="600px"/>
              <div className='frameFooter'>
                <h5>Ptax-Overall Dashboard</h5>
                <p className='mb-0' onClick={()=>handleViewFullscreen(powerBIUrl4)}>
                  <Icon.ArrowsFullscreen className='me-2'/>View Fullscreen
                </p>
              </div>
            </Col>

            <Col md={12} className='mb-5 frame'>
              <PowerBIFrame src={powerBIUrl5} height="600px"/>
              <div className='frameFooter'>
                <h5>Ptax-Defaulter_Dashboard</h5>
                <p className='mb-0' onClick={()=>handleViewFullscreen(powerBIUrl5)}>
                  <Icon.ArrowsFullscreen className='me-2'/>View Fullscreen
                </p>
              </div>
            </Col>

            <Col md={12} className='mb-5 frame ' style={{paddingBottom:'-50px'}}>
              <PowerBIFrame src={powerBIUrl6} height="600px"/>
               <div className='frameFooter'>
                <h5>Ptax-Collection Analysis LY Vs TY</h5>
                <p className='mb-0' onClick={()=>handleViewFullscreen(powerBIUrl6)}>
                  <Icon.ArrowsFullscreen className='me-2'/>View Fullscreen
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    // </NoRightClickDiv>
    
  )
}

export default Analytics;