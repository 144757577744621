import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Spinner,
  Table,
  Modal,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import Pagination from "../Common/Pagination";

const DeleteBillHistory = () => {
    const userState = useSelector((state) => state.loginData.userData);
    const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)
    const navigate = useNavigate();
  
    // Variables for Zone-Gat Visitors Data ------------
    const [contractor, setContractor] = useState();
  
    // Delete Modal and state
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [apiLoader, setApiLoader] = useState(false);
  
    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(50);
    const [noOfPages, setNoOfPages] = useState(0);
  
    // function for pagination
  
    const handlePageChange = (pageNumber) => {
      setPageNo(pageNumber);
    };
  
    const handleDocsPerPage = (docsPageProp) => {
      setPageNo(1);
      setDocPerPage(docsPageProp);
    };
  
    //   useEffect API Calls
    useEffect(() => {
      getVisitReportList();
    }, [docPerPage, pageNo]);
  
    // get List API
    const getVisitReportList = async () => {
        setApiLoader(true)
      await fetch(
        process.env.REACT_APP_BASE_URL +
          "/billDistribution/getMavimDeletedBills",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            documentsPerPage: docPerPage,
            page: pageNo,
            financialYear : financialYear
          }),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            setApiLoader(false)
            setContractor(response);
            setNoOfPages(response.noOfPages);
          }else{
            setApiLoader(false)
          }
        })
        .catch((error) => {
          console.error(error);
          setApiLoader(false)
        });
    };
  
    const openDelete = (item) => {
      setDeleteId(item?.visit_id);
      setShowDelete(true);
    };
  
    const MavimDeleteBill = async () => {
      await fetch(
        process.env.REACT_APP_BASE_URL + "/billDistribution/mavimDeleteBill",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            visit_id: deleteId,
            mavimDeleteId: userState._id, // login user userId
            mavimDeleteName: userState.name, // login user Name
            mavimDeleteContactNo: userState.contactNo, // login user ContactNo
          }),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            setShowDelete(false)
            setDeleteId()
           getVisitReportList()
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
  
    return (
      <div className="visitReport">
        <ToastContainer />
        <Container>
          <Row className="visitors mt-2">
            <div className="container tbl-container p-0">
              <div className="row tbl-fixed p-0" style={{ maxHeight: "65vh" }}>
                <Table responsive="lg" hover>
                  <thead>
                    <th>Sr No.</th>
                    <th>Propety Code</th>
                    <th>Propety Owner Name</th>
                    <th>Propety Owner Contact No</th>
                    <th>Propety Address</th>
                    <th>Zone Name</th>
                    <th>Gat Name</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    { apiLoader ? (<center><Spinner /></center>) : contractor &&
                      contractor?.data?.length > 0 ?
                      contractor?.data &&  contractor?.data?.map((row, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {pageNo !== 1 ? (
                                <> {index + 1 + docPerPage * (pageNo - 1)}</>
                              ) : (
                                <>{index + 1}</>
                              )}
                            </td>
                            <td>{row?.propertyCode ? row?.propertyCode : "-"}</td>
                            <td>{row?.propertyName ? row?.propertyName : "-"}</td>
                            <td>
                              {row?.propertyContactNo
                                ? row?.propertyContactNo
                                : "-"}
                            </td>
                            <td>
                              {row?.propertyAddress ? row?.propertyAddress : "-"}
                            </td>
                            <td>{row?.zonename ? row?.zonename : "-"}</td>
                            <td>{row?.gatname ? row?.gatname : "-"}</td>
                            <td className="text-center">
                            <Icon.Eye
                              className="me-2"
                              style={{ cursor: "pointer" }}
                              size={25}
                              onClick={() =>{
                                localStorage.setItem('report', JSON.stringify(row))
                                navigate("/dashboard/view-delete-report")}
                              }
                            />
                            </td>
                          </tr>
                        );
                      }) : (
                        <tr>No data fouund</tr>
                      ) }
                  </tbody>
                </Table>
              </div>
              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-start mb-0">
                    <Icon.People className="me-2" />
                    Total - <strong>{contractor?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
}

export default DeleteBillHistory