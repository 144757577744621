import React, { useCallback, useEffect, useState } from "react";
import {
  Card, Container, Row, Col, Spinner, Button,
  Form,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
import Calender from "../../Assets/Calender.svg";
import Location from "../../Assets/Location.svg";
import Phone from "../../Assets/Phone.svg";
import PropertyCode from "../../Assets/PropertyCode.svg";

import { ToastContainer, toast } from "react-toastify";

const Visits = () => {
  const location = useLocation();
  // const visitor = location.state;
  const visitor = JSON.parse(localStorage.getItem('visitor'));

  // Visits Data Variables ------------
  const [visit, setVisit] = useState([]);
  const [count, setCount] = useState("");

  const [filterVisit, setFilterVisit] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [visitLoader, setVisitLoader] = useState(false)

  const noLatLong = () => toast.error("Sorry, Property location not found.");

  useEffect(() => {
    getVisits();
  }, []);


  const getVisits = async () => {
    setVisitLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/visitreport/getvisitbycontractor",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ visitingPerson_id: visitor?._id }),
      }).then((res) => res.json())
      .then((response) => {
        setVisit(response.data);
        setCount(response.count);
        setFilterCount(response.count)
        setVisitLoader(false);
      })
      .catch((err) => {
        console.log("Error While Getting Visits", err);
        setVisitLoader(false);
      });
  };

  const showInMapClicked = (row) => {
    if (row.propertyLat && row.propertyLong) {
      window.open(
        "https://maps.google.com?q=" + row.propertyLat + "," + row.propertyLong
      );
      console.log(
        "https://maps.google.com?q=" + row.propertyLat + "," + row.propertyLong
      );
    } else {
      noLatLong();
    }
  };

  const [found, setFound] = useState("0");

  useEffect(() => {
    setVisitLoader(true);
    if (found == "isMobileUpdated" || found == "isAlternateMobileUpdated" || found == "isAddressUpdated" || found == "isAltAddressUpdated" || found == "isPropertyFound") {
      const test = visit?.filter((x) => x[found] === true);
      setFilterVisit(test)
      setFilterCount(test.length);
      setVisitLoader(false);
    }
  }, [found]);

  //   console.log("showFilterData", showFilterData);
  return (
    <div className="visits">
      <ToastContainer />
      <Container>
        <Row className="d-flex align-items-center justify-content-between mb-3 w-100 mt-3">
          <Col md={4}>
            <h5 className="mb-0">
              <Icon.PersonFill className="me-1" /> Contractor - {visitor.name}
            </h5>
          </Col>
          <Col md={4} className="text-start text-md-center mt-3 mt-md-0">
            <h5 className="mb-0"><Icon.PersonFillAdd className="me-1" />Visits Count: {filterCount}
            </h5>
          </Col>
          <Col md={4} className="justify-content-end d-flex mt-3 mt-md-0">
            <Form.Select onChange={(e) => { setFound(e.target.value); }} className="w-100 w-md-75">
              <option value="0" selected disabled>Filter Properties</option>
              <option value="isMobileUpdated">Is Mobile Updated</option>
              <option value="isAlternateMobileUpdated">Is Alt. Mobile Updated</option>
              <option value="isAddressUpdated">Is Address Updated</option>
              <option value="isAltAddressUpdated">Is Alt. Address Updated</option>
              <option value="isPropertyFound">Property Not Found</option>
            </Form.Select>
            {/* <label className="switch">
                        <input
                        className="switch-input"
                        type="checkbox"
                        onChange={() => isPropertyFound()}/>
                        <span className="switch-label" data-on="Found" data-off="Not Found"></span>{" "}
                        <span className="switch-handle"></span>
            </label> */}
          </Col>
        </Row>
        <Row className="visitsMainCard">
          {visitLoader ? <center><Spinner variant="primary" className="mt-3" /></center>
            : visit !== null && visit !== undefined && visit.length > 0 && filterCount != 0 ? (
              (filterVisit.length > 0 ? filterVisit : visit).map((row) => {
                return (
                  <Col md={6} className="gy-3">
                    <Card className="h-100">
                      <div>
                        <img
                          src={PropertyCode}
                          className="img"
                          alt="Property Code"
                          width={20}
                        />
                        <h6>Property Code</h6>&nbsp;-<p>{row?.propertyCode}</p>
                      </div>
                      <div>
                        <img src={Calender} className="img" alt="Meeting Date" width={16} />
                        <h6>Visit Date</h6>&nbsp;-<p>{moment(row?.visitDate).format("DD-MM-YYYY")}</p>
                      </div>

                      {row.isMobileUpdated === true ? (
                        <div>
                          <img
                            src={Phone}
                            alt="Updated Mobile"
                            width={20}
                            className="img"
                          />
                          <h6>Updated Mobile</h6>&nbsp;-<p>{row.mobileUpdated}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {row.isAlternateMobileUpdated === true ? (
                        <div>
                          <img
                            src={Phone}
                            alt="Alternate Mobile"
                            width={20}
                            className="img"
                          />
                          <h6>Alternate Mobile</h6>&nbsp;-
                          <p>{row.alternateMobileUpdated}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {row.isAddressUpdated === true ? (
                        <div>
                          <img
                            src={Location}
                            alt="Updated Address"
                            width={20}
                            className="img"
                          />
                          <h6>Updated Address</h6>&nbsp;-
                          <p>{row.addressUpdated}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div>
                        <Button
                          className="btn-location"
                          onClick={() => showInMapClicked(row)}
                        >
                          <img src={Location} alt="Location" width={20} />
                          Property Address
                        </Button>
                      </div>
                    </Card>
                  </Col>
                );
              })
            ) : (
              "No Visits Data Found"
            )}
        </Row>
      </Container>
    </div>
  );
};

export default Visits;
