import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Table, Spinner, Tab, Nav } from 'react-bootstrap';
import * as  Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from "react-toastify";
import Refresh from '../../Assets/Refresh.svg';
import LineChart from '../Charts/LineChart';

import MultibarChart from '../Charts/MultibarChart';
import GraphLoader from '../../Assets/GraphLoader.gif';
import { useSelector } from 'react-redux';

const Distribution = () => {
  const [key, setKey] = useState("chart");
  const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)

  // Variables for Zone & Gat LIST ------------
  const [zoneList, setZoneList] = useState([]);
  const [allGatList, setAllGatList] = useState([]);

  //Varaibles for Filtering Gat depending on Zone ----------
  const [filterGat, setFilterGat] = useState([]);

  // Variables for Zone & Gat DATA -------------
  const [zone, setZone] = useState("");
  const [gat, setGat] = useState("");

  // Variables for Zone-Gat Visitors Data ------------
  const [allDistribution, setAllDistribution] = useState([]);
  const [filterDistribution, setFilterDistribution] = useState([]);
  const [timeStamp, setTimeStamp] = useState("");
  const [zoneChartLabel, setZoneChartLabel] = useState('');
  const [zoneChart, setZoneChart] = useState('');
  const [users, setUsers] = useState('');

  // Variables for Errors -------------
  const SearchError = () => toast.error("Please select any zone/gat");
  const [loader, setLoader] = useState(false);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getZoneWiseChart();
    // getZoneWiseAnalysis();
    getZoneList();
    getGatList();
    getAllDistribution();
  }, []);

  useEffect(() => {
    getFiltertedGat();
    setGat("");
  }, [zone]);

  const getFiltertedGat = () => {
    const result = allGatList !== undefined && allGatList.length > 0
      ? allGatList.filter((row) => row.zonekey == zone) : "";
    setFilterGat(result);
  };


  // Function for Fetching ZONE LIST --------------------
  const getZoneList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/zoneData/getZoneData`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setZoneList(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };

  // Function for Fetching GAT LIST --------------------
  const getGatList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/gatData/getGatData`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((response) => {
        setAllGatList(response.data);
        // setFilterGat(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };

  // Function for Fetching ZONE & GAT DATA --------------------

  const getAllDistribution = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/visitreport/getvisitbyzonegatfilter", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        zone: "",
        gat: "",
        financialYear: financialYear
      }),
    }).then((res) => res.json())
      .then((response) => {
        setAllDistribution(response.data);
        setFilterDistribution(response.data);
        setTimeStamp(response.timestamp);
        setLoader(false);
        setDisabled(false);
      })
      .catch((err) => {
        console.log("Error while Fetching Visitor Zone/Gat", err);
        setLoader(false);
      });
  };

  const getFilterDistribution = async () => {
    if (!zone) {
      SearchError();
    } else if (zone && gat == "") {
      setLoader(true);

      const resultData = filterDistribution !== undefined && filterDistribution.length > 0 ?
        filterDistribution.filter((itm) => parseInt(itm.zonekey) === parseInt(zone)) : "";
      setAllDistribution(resultData);
      setLoader(false);
    }
    else if (zone && gat) {
      setLoader(true);

      const resultData = filterDistribution !== undefined && filterDistribution.length > 0 ?
        filterDistribution.filter((itm) => parseInt(itm.zonekey) === parseInt(zone) && parseInt(itm.gat) === parseInt(gat)) : "";
      setAllDistribution(resultData);
      setLoader(false);
    }
  };




  const getZoneWiseChart = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + '/graph/targetanalysis', {
      method: 'GET'
    }).then((res) => res.json()).then((response) => {
      setZoneChartLabel(response.zones);
      let dataArray = [];

      dataArray.push({
        type: 'bar',
        label: "Bills Distributed",
        fill: true,
        data: response.billsDistributed,
        backgroundColor: "rgba(17,141,255,255)"
      });

      dataArray.push({
        type: 'bar',
        label: "Target",
        fill: true,
        data: response.targets,
        backgroundColor: "rgba(18,35,158,255)"
      });
      // dataArray.push( {
      //   type:'line',
      //   label:"Users",
      //   fill:true,
      //   data:response.users,
      //   backgroundColor:"rgba(0,255,0)"
      // });
      setZoneChart(dataArray);

      let CountArray = [];
      CountArray.push({
        label: "Users",
        data: response.users,
        borderColor: "red",
      });
      setUsers(CountArray);
    }).catch((err) => {
      console.log("Err at ZoneWise Chart", err);
    })
  }

  // const [zoneWiseAnalysis, setzoneWiseAnalysis] = useState('');
  // const [analysisLoader, setAnalysisLoader] = useState(false);
  // const getZoneWiseAnalysis=async()=>{
  //   setAnalysisLoader(true);
  //   await fetch(process.env.REACT_APP_BASE_URL + '/billdistribution/zonewiseanalysis',{
  //     method:'GET'
  //   }).then((res)=>res.json()).then((response)=>{
  //     console.log('Response at zoneWise Analysis', response);
  //     setzoneWiseAnalysis(response.data);
  //     setAnalysisLoader(false);
  //   }).catch((err)=>{
  //     console.log('Err at zoneWise Analysis', err);
  //     setAnalysisLoader(false);
  //   })
  // }

  return (
    <div className='distribution'>
      <ToastContainer />
      <Container>
        <Row className='d-flex justify-content-center'>
          <Col className='tab'>
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Row className="flex-column align-items-center">
                <Col xs={12} className='d-flex justify-content-end p-0'>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="chart">Bill Chart</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="report">Bill Report</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>

                <Col className='mt-4'>
                  <Tab.Content>
                    <Tab.Pane eventKey="report">
                      <Row className="justify-content-center mt-3">
                        <Col md={7}>
                          <Row>
                            <Col md={4} className="mb-3 mb-md-0">
                              <Form.Select value={zone} onChange={(e) => setZone(e.target.value)} disabled={disabled}>
                                <option value="" selected disabled> Select Zone</option>
                                {zoneList !== undefined && zoneList.length > 0 ? (
                                  zoneList.map((row) => {
                                    return (
                                      <option value={row.zonekey}>{row.zonename + "-" + row.zoneno}</option>
                                    );
                                  })
                                ) : (
                                  <option value="">No Data Found</option>
                                )}
                              </Form.Select>
                            </Col>

                            <Col md={4} className="mb-3 mb-md-0">
                              <Form.Select value={gat} onChange={(e) => setGat(e.target.value)} disabled={disabled}>
                                <option value="" selected disabled>Select Gat</option>
                                {filterGat !== undefined && filterGat.length > 0 ? (
                                  (filterGat.length > 0 && filterGat).map((row) => {
                                    return (
                                      <option value={row.gatname}>{row.mar_gatname}</option>
                                    );
                                  })
                                ) : (<option value="">No Data Found</option>)}
                              </Form.Select>
                            </Col>

                            <Col md={4} className="d-flex justify-content-md-start justify-content-center">
                              <Button className="btn btn-submit" onClick={getFilterDistribution}>
                                <Icon.Search className="me-2" size={14} />Search distribution
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row className='justify-content-center mt-4'>
                        <Col md={5} className='d-flex justify-content-center align-items-center'>
                          {timeStamp ? <div className='d-flex justify-content-center align-items-center'>
                            <h6 className='mb-0 me-2'>Last updated:</h6>
                            <p className='mb-0'>{timeStamp}</p>
                            <img src={Refresh} className='ms-2 mb-2 refresh' onClick={getAllDistribution} /> </div> : ""}
                        </Col>
                      </Row>

                      <Row className="visitors mt-1">
                        <div className="container tbl-container p-0">
                          <div className="row tbl-fixed p-0" style={{ maxHeight: '70vh' }}>
                            <Table responsive="lg" hover>
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Bill Distributed</th>
                                  <th>Target</th>
                                  <th>Zone</th>
                                  <th>Gat</th>
                                </tr>
                              </thead>

                              {loader ? (<Spinner variant="primary" className="mt-2" />) : (
                                <tbody>
                                  {allDistribution !== null && allDistribution !== undefined && allDistribution.length > 0
                                    ? allDistribution.map((row, index) => {
                                      return (
                                        <tr style={{ textAlign: "center", cursor: "pointer" }}>
                                          <td>{index + 1}</td>
                                          <td style={{ whiteSpace: 'nowrap' }}>{row?.billsDistributed}</td>
                                          <td>{row?.target}</td>
                                          <td style={{ whiteSpace: 'nowrap' }}>{row?.zone}</td>
                                          <td>{row?.gat}</td>
                                        </tr>
                                      );
                                    })
                                    : "No data found"}
                                </tbody>
                              )}
                            </Table>
                          </div>
                        </div>
                      </Row>

                    </Tab.Pane>

                    <Tab.Pane eventKey="chart">
                      <Row style={{ height: '75vh' }}>
                        <div className='d-flex justify-content-center align-items-center mb-2'>
                          <Icon.ReceiptCutoff className='me-2' size={22} color='#073c7c' />
                          <h5 className='p-0 text-center mb-0'>Bill Distribution Chart</h5>
                        </div>

                        {zoneChart !== null && zoneChart !== undefined && zoneChart.length > 0 ?
                          <MultibarChart chartLabels={zoneChartLabel} chartData={zoneChart} chartHeading="ZoneWise Bill Distribution" /> :
                          <div className='d-flex justify-content-center align-items-center h-100'>
                            <img src={GraphLoader} alt="Loader" width={200} />
                          </div>}

                        <Row className='justify-content-center mt-4 p-0'>
                          <Col md={10} className='mb-3'>
                            {
                              users !== undefined && users !== null && users.length > 0 ?
                                <LineChart months={zoneChartLabel} Array={users} chartHeading="Zone wise users" /> : ''
                            }
                          </Col>
                        </Row>
                      </Row>

                    </Tab.Pane>


                    {/* <Tab.Pane eventKey="analysis">
                {analysisLoader ? <center><Spinner/></center> :
                <Row>
            <div className="tbl-container p-0">
            <div className="tbl-fixed p-0" style={{maxHeight:'80vh'}}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th style={{width:'maxContent'}}>Zone</th>
                    <th>Till Yesterday</th>
                    <th>Today</th>
                    <th>Total</th>
                  </tr>
                </thead>

          
                  <tbody>
                    {zoneWiseAnalysis !== null && zoneWiseAnalysis !== undefined && zoneWiseAnalysis.length > 0
                      ? zoneWiseAnalysis.map((row, index) => {
                          return (
                            <tr style={{ textAlign: "center"}}>
                              <td>{index + 1}</td>
                              <td style={{whiteSpace:'nowrap'}}>{row?.zone}</td>
                              <td>{row?.tillYesterday}</td>
                              <td>{row?.today}</td>
                              <td>{row?.total}</td>
                            </tr>
                          );
                        })
                      : "No contractors data found"}
                  </tbody>
              </Table>
            </div>
          </div>
                </Row>
                }
              
               
                            </Tab.Pane> */}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>



        {/* ----- ZoneWise Distribution Graph ------ */}


      </Container>
    </div>
  )
}

export default Distribution;