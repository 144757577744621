import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Spinner,
  Alert,
  Button,
} from "react-bootstrap";
import moment from "moment";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import { useSelector } from "react-redux";

const SmsDetails = () => {
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState("");
  const [propertycode, setPropertyCode] = useState("");
  const [count, setCount] = useState(null);
  const [showNoDataAlert, setShowNoDataAlert] = useState(false);

  const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)

  const [dateErr, setDateErr] = useState(false);
  const [propCodeErr, setPropCodeErr] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  const getSMSDetails = async (a, b) => {
    setLoader(true);

    try {
      const res = await fetch(
        process.env.REACT_APP_BASE_URL + "/tc/getSMSSentDetails",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            callingDate: b,
            propertyCode: a,
            documentsPerPage: docPerPage,
            pageNo: pageNo,
            financialYear : financialYear
          }),
        }
      );

      const response = await res.json();
      if (response.data !== null) {
        setData(response?.data);
      } else {
        setData([]);
      }
      setNoOfPages(response.noOfPages);
      setCount(response?.count);
      setShowNoDataAlert(!response?.data);
      setLoader(false);
    } catch (err) {
      console.log("Err while getting SMS Sent Details", err);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getSMSDetails("", ""); // Initial call when the component mounts
  }, [pageNo, docPerPage]);

  const handleSearchByPropertyCode = () => {
    if (!propertycode) {
      setPropCodeErr(true);
    } else {
      getSMSDetails(propertycode, "");
    }
  };

  const handleSearchByDate = () => {
    if (!date) {
      setDateErr(true);
    }
    getSMSDetails("", date);
  };

  const handleCloseAlert = () => {
    setShowNoDataAlert(false);
  };

  const handleReset = () => {
    getSMSDetails("","")
    setDate("")
    setPropertyCode("")
  }

  return (
    <div className="SmsSentReport JaptiKarvai">
      <Container>
        <Row className="justify-content-center mt-4">
          <Col md={4} className="mt-0 mb-4 mb-md-0 searchCard">
            <div className="label mb-2 mx-auto">
              <p>Search by Property Code</p>
            </div>
            <div className="d-flex mt-1">
              <Form.Control
                placeholder="Property Code"
                pattern="[0-9]*"
                value={propertycode}
                onChange={(e) => {
                  if (!/^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                    e.target.setCustomValidity("Please enter only numbers.");
                  } else {
                    e.target.setCustomValidity("");
                    setPropertyCode(e.target.value);
                    setShowNoDataAlert(false);
                  }
                }}
              />

              <Button
                className="btn-submit ms-3"
                onClick={handleSearchByPropertyCode}
              >
                <Icon.Search className="me-2" size={14} />
                Search
              </Button>
            </div>
            {propCodeErr ? (
              <p className="errMsg">Please enter valid property code</p>
            ) : null}
          </Col>

          <Col md={{ span: 4, offset: 1 }} className="searchCard">
            <div className="label mb-2 mx-auto">
              <p>Search by date</p>
            </div>
            <div className="d-flex mt-1">
              <Form.Control
                type="date"
                value={date}
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) => {
                  setDate(moment(e.target.value).format("YYYY-MM-DD"));
                }}
              />
              <Button className="btn-submit ms-3" onClick={handleSearchByDate}>
                <Icon.Search className="me-2" size={14} />
                Search
              </Button>
              <Button className="btn-submit ms-3" onClick={handleReset}>Reset</Button>
            </div>
            {dateErr ? (
              <p className="errMsg">Please select valid date</p>
            ) : null}
          </Col>
        </Row>
        <Row className="mt-3 mb-5">
          {/* {showNoDataAlert && (
            <Alert variant="danger" onClose={handleCloseAlert} dismissible>
              मालमत्ता धारकाला sms पाठविला नाही
            </Alert>
          )} */}
          {count !== 0 && <h5 className="text-center">Count - {count}</h5>}
          {data && (
            <div className="container tbl-container p-0">
              <div className="row tbl-fixed p-0" style={{ maxHeight: "68vh" }}>
                <Table responsive="lg" hover>
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Property Code</th>
                      <th>Mobile No.</th>
                      <th>Property Name</th>
                      <th>Sms Send</th>
                      <th>Calling Date</th>
                    </tr>
                  </thead>

                  {loader ? (
                    <Spinner variant="primary" className="mt-2" />
                  ) : (
                    <tbody>
                      {data !== null && data !== undefined && data?.length > 0
                        ? data.map((row, index) => (
                            <tr key={index}>
                              <td>
                                {pageNo !== 1 ? (
                                  <> {index + 1 + docPerPage * (pageNo - 1)}</>
                                ) : (
                                  <>{index + 1}</>
                                )}
                              </td>
                              <td style={{ maxWidth: "40px" }}>
                                {row?.propertyCode}
                              </td>
                              <td style={{ maxWidth: "120px" }}>
                                {row?.propertyContactNo}
                              </td>
                              <td style={{ maxWidth: "200px" }}>
                                {row?.propertyName}
                              </td>
                              <td style={{ maxWidth: "200px" }}>
                                {row?.isSMSSent ? "होय" : "नाही"}
                              </td>
                              <td>
                                {moment(row?.callingDate).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          ))
                        : "No Data Found"}
                    </tbody>
                  )}
                </Table>
              </div>
            </div>
          )}
        </Row>
        <Row>
          {/* <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
            <h6 className="text-center">
              <Icon.BarChart className="me-2" />
              Total - <strong>{data?.count}</strong>
            </h6>
          </Col> */}
          {/* <Col md={8} className="d-flex justify-content-end"> */}
          <Pagination
            currentPage={pageNo}
            totalPages={noOfPages}
            onPageChange={handlePageChange}
            onDocsPerPage={handleDocsPerPage}
            docsPerPage={docPerPage}
          />
          {/* </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default SmsDetails;
