import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const InternalDev = () => {

  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [startDateErr, setStartDateErr] = useState(false);
  const [endDateErr, setEndDateErr] = useState(false);


  // useEffect(()=>{
  //     getCallLogReport();
  // },[])

  const validate = () => {
    let isValid = true;
    if (!startDate) {
      isValid = false;
      setStartDateErr(true);
    }
    else if (!endDate) {
      isValid = false;
      setEndDateErr(true);
    }
    return isValid;
  }

  // for export date wise data
  const getCallLogReport = async () => {
    if (validate()) {
      setLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + '/tc/getAllCallings', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fromDate: startDate,
          toDate: endDate
        })
      }).then((res) => res.json()).then((response) => {
        console.log("response", response?.data);
        if (response.message === "No Data Found") {
          toast.error("No Data Found");
          setLoader(false)
        }

        const keys_to_keep = ['propertyCode', 'propertyContactNo', 'propertyName', 'propertyAddress', 'zonename', 'gatname', 'callingPerson_ContactNo',
          'callingPerson_Name', 'callingDate', 'callingStatus', 'callingNumber', 'customer_time', 'talk_duration'];

        const ExcelData = response?.data.map(e => {
          const obj = {};
          keys_to_keep.forEach(k => obj[k] = e[k])
          return obj;
        });

        // const redux1 = list => list.map(o => Object.fromEntries(
        //     keys_to_keep.map(k => [k, o[k]])
        // ));
        // downloadExcel(ExcelData);
        downloadExcel(ExcelData, `CallLog Report ${moment(startDate).format("DD-MM-YYYY") + ' To ' + moment(endDate).format("DD-MM-YYYY")}.xlsx`);
        setLoader(false);
      }).catch((err) => {
        console.log("Error while getting Excel Call Report", err);
        setLoader(false);
      })
    }
    else {
      setLoader(false);
    }
  }

  // for export all data
  const getCallLogReportAllData = async () => {
    setStartDate('');
    setEndDate('');
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/tc/getAllCallings', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fromDate: '',
        toDate: '',
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "No Data Found") {
          toast.error("No Data Found");
          setLoader(false)
        }
        else {
          const keys_to_keep = [
            'propertyCode',
            'propertyContactNo',
            'propertyName',
            'propertyAddress',
            'zonename',
            'gatname',
            'callingPerson_ContactNo',
            'callingPerson_Name',
            'callingDate',
            'callingStatus',
            'callingNumber',
            'customer_time',
            'talk_duration',
          ];

          const ExcelData = response?.data.map((e) => {
            const obj = {};
            keys_to_keep.forEach((k) => (obj[k] = e[k]));
            return obj;
          });

          downloadExcel(ExcelData, `CallLog Report.xlsx`);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log('Error while getting Excel Call Report', err);
        setLoader(false);
      });

  }


  const downloadExcel = (heading, fileName) => {
    let dataSet = [];
    dataSet = heading;
    const worksheet = XLSX.utils.json_to_sheet(dataSet);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  };



  return (
    <div className='InternalDev'>
      <ToastContainer />
      <Container>
        <div className='d-flex justify-content-center align-items-center'>
          <Icon.DatabaseLock className='me-2' size={22} color='#073c7c' />
          <h3 className='mb-0'>Internal Dev</h3>
        </div>
        <Row className='justify-content-center my-4'>
          <Col md={5}>
            <Card>
                <Card.Header className='py-3'>
                  <h5 className='text-center mb-0'>
                  <Icon.PersonVcard className='me-2' size={18} />Export Call Log Report</h5>
                </Card.Header>

                <Card.Body className='py-4'>
                  <Row className='justify-content-between'>
                    <Col md={5}>
                      <Form.Group className='mb-3 mb-md-0'>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="date" value={startDate} onChange={(e) => {
                          setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                          e.target.value.length > 0 ? setStartDateErr(false) : setStartDateErr(true)
                        }} />
                        {startDateErr ? <p className='errMsg'>Please select start date</p> : ''}
                      </Form.Group>
                    </Col>

                    <Col md={5}>
                      <Form.Group className='mb-3 mb-md-0'>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control type="date" disabled={startDate ? false : true} min={startDate} value={endDate}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={(e) => { setEndDate(moment(e.target.value).format("YYYY-MM-DD")); e.target.value.length > 0 ? setEndDateErr(false) : setEndDateErr(true) }} />
                        {endDateErr ? <p className='errMsg'>Please select end date</p> : ''}
                      </Form.Group>
                    </Col>

                    <div>
                      {
                        loader ? <center><Spinner variant='primary' /></center> :
                            <Button className="btn-submit mt-3 mx-auto" onClick={getCallLogReport} ><Icon.ArrowDownCircle className='me-2' />Export Excel</Button>
                      }
                    </div>
                  </Row>
                </Card.Body>
              
              {
                loader ? '' :
                  <Card.Footer className='py-3'>
                              <Button className="btn-submit  mx-auto" onClick={getCallLogReportAllData} ><Icon.ArrowDownCircle className='me-2' />All Data Export Excel</Button>
                  </Card.Footer>
              }
                 
             
            </Card>
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default InternalDev;