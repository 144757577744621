import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useLocation, useSearchParams } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import BarChart from '../Charts/BarChart';
import GraphLoader from "../../Assets/GraphLoader.gif"

const CallLogReport = () => {

    const location = useLocation();
    const visitorFromState = location.state;
    const visitorFromStorage = localStorage.getItem('visitor'); 
    const visitor = visitorFromState || (visitorFromStorage && JSON.parse(visitorFromStorage));
    
    const [loader, setLoader] = useState(false);

    // for user telecalling data
    const [callLoader, setCallLoader] = useState(false);
    const [call, setCall] = useState([]);
    const [callCounts, setCallCounts] = useState([]);
    const [callTime, setCallTime] = useState('');

    const handleCallLoader = () => {
        setCallLoader(true);
    }

    useEffect(() => {
        getProperty();
    }, [callTime ? callTime : ""])

    useEffect(() => {
        getUserCall();
    }, [])

    // api for user calling
    const getUserCall = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/user/teleCallingUserGraph', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ contactNo: visitor?.contactNo })
        }).then((res) => res.json()).then((response) => {
            setCall(response?.labels);
            setCallCounts(response?.values);
            setCallLoader(false)
        }).catch((err) => {
            console.log("Err while Get User Calling Chart", err);
        })
    }

    const [  property, setProperty] = useState('');
    const getProperty = async () => {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/tc/getUserCalling', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                callingPerson_ContactNo: visitor?.contactNo,
                minTime: callTime ? callTime.split('-')[0] : '',
                maxTime: callTime ? callTime.split('-')[1] : '',
            })
        }).then((res) => res.json()).then((response) => {
            if(response?.data !== null){
            setProperty(response.data);
            }else{
                setProperty([])
            }
            setLoader(false);
        }).catch((err) => {
            console.log("Err at Getting Property", err);
            setLoader(false);
        })
    }

    return (
        <div className='visits'>
            <Container>
                <Row className='d-flex justify-content-between'>
                    <Col md={4} className='d-flex align-items-center justify-content-start mb-2 mb-md-0'>
                        <Icon.PersonFill className="me-1" size={20} />
                        <h5 className="mb-0">{visitor?.name}</h5>
                    </Col>

                    <Col md={4} className='d-flex align-items-center justify-content-start mb-2 mb-md-0'>
                        <Icon.ClockFill className='me-2' />
                        <h5 className='mb-0'>Call Duration - {visitor?.talkDuration}</h5>
                    </Col>

                    <Col md={2} className='d-flex align-items-center justify-content-start justify-content-md-end mb-2 mb-md-0'>
                        <Icon.Bullseye className="me-2" size={18} />
                        <h5 className="mb-0">{visitor?.zonename}</h5>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} className='my-3 d-flex justify-content-center align-items-center'>
                        <Card className="ChartCard" style={{ height: "160px", maxHeight: "250px", padding: '20px 10px 12px 0px' }}>
                            <h6 className='mb-0 mx-3'>TeleCalling Status Report</h6>
                            {
                                callLoader ?
                                    <center>
                                        <img src={GraphLoader} width="150" maxHeight="250px" />
                                    </center> :
                                    <BarChart indexAxis='y' chartLabels={call} chartLabel={['Call Connected']}
                                        ColorArray={['rgba(28, 255, 68, 0.43)', 'rgba(255, 0, 0, 0.4)']}
                                        BorderArray={['rgba(0, 225, 39, 1)', 'rgba(243, 0, 0, 1)']}
                                        chartData={callCounts} handleCallLoader={() => { handleCallLoader() }} />
                            }
                        </Card>
                    </Col>
                </Row>

                <Row className="justify-content-between align-items-center mt-2">
                    <Col md={4}>
                        <h5 className='text-center text-md-start'>
                            <Icon.ArrowDownCircleFill className='me-2' size={17} />TeleCalling Logs Report</h5>
                    </Col>
                    <Col md={3}>
                        <Form.Label>Filter by call duration seconds</Form.Label>
                        <Form.Select value={callTime}  onChange={(e) => setCallTime(e.target.value)}>
                            <option value="">All</option>
                            <option value="0-5">0-5</option>
                            <option value="0-10">0-10</option>
                            <option value="10-20">10-20</option>
                            <option value="20-30">20-30</option>
                            <option value="30-60">30-60</option>
                        </Form.Select>
                    </Col>
                    <Col md={4}>
                        <div className='callLabels d-flex justify-content-center justify-content-md-end'>
                            <p className='d-flex text-danger me-4'><Icon.CircleFill className='me-2' />Dropped</p>
                            <p className='d-flex text-success'><Icon.CircleFill className='me-2' />Other</p>
                        </div>
                    </Col>
                </Row>
                
                <Row className="visitsMainCard px-3 pb-4">
                    {
                        loader ? <center><Spinner variant='primary' className='mt-3' /> </center> :
                            property !== undefined && property.length > 0 && property !== null ?
                                property?.map((row, index) => {
                                    return (
                                        <Col md={4} className='gy-4'>
                                            <Card className={row?.callingStatus === "DROP" ? 'drop h-100' : 'ni h-100'}>
                                                <div>
                                                    <Icon.Person size={16} className='me-2' />
                                                    <h6 style={{ whiteSpace: 'nowrap' }}>Property Name</h6>&nbsp;-<p>{row?.propertyName.split(",")[0]}</p>
                                                </div>
                                                <div>
                                                    {/* {/ <img src={PropertyCode}  className="img" alt="Property Code" width={20}/ > /} */}
                                                    < Icon.Building size={14} className='me-2' />
                                                    <h6>Property Code</h6>&nbsp;-<p>{row?.propertyCode}</p>
                                                </div>

                                                <div>
                                                    <Icon.Telephone size={14} className='me-2' />
                                                    <h6>Dialed Mobile</h6>&nbsp;-<p>{row?.callingNumber}</p>
                                                </div>

                                                <div>
                                                    <Icon.GeoAlt size={14} className='me-2' />
                                                    <h6>Property Address</h6>&nbsp;-<p>{row?.propertyAddress}</p>
                                                </div>

                                                <div>
                                                    <Icon.Alarm size={14} className='me-2' />
                                                    <h6 className='mb-0'>Call Time</h6>&nbsp;-<p>{row?.customer_time ? new Date(row?.customer_time).toLocaleTimeString() : ''}</p>
                                                </div>

                                                <div>
                                                    <Icon.CalendarRange size={14} className='me-2' />
                                                    <h6 className='mb-0'>Call Duration</h6>&nbsp;-<p>{row?.talk_duration ? row?.talk_duration : '-'}</p>
                                                </div>

                                                <div>
                                                    <Icon.TelephonePlus size={14} className='me-2' />
                                                    <h6 className='mb-0'>Call Status</h6>&nbsp;-<p>{row?.callingStatus}</p>
                                                </div>

                                                <div className='mb-3'>
                                                    <Icon.PlayCircle size={14} className='me-2 ' />
                                                    <h6 className='mb-0'>Call Recording</h6>&nbsp;- <a href={row?.recording_file} className='viewCallRec'
                                                        target="_blank"> Play</a>
                                                </div>

                                            </Card>
                                        </Col>
                                    )
                                }) : "No Call Logs Data Found"
                    }
                </Row>
            </Container>
        </div>
    )
}

export default CallLogReport;